import { Card, Divider, FlexClm } from '../style';
import { IRoleIdentifier, UsersCountByAccessLevel, getMonitoringData, getServiceData } from '../api/fetch';
import { Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import React, { PureComponent, useState } from 'react';
import Select, { StylesConfig, ThemeConfig } from 'react-select';

import { AccessLevel } from '../model/enum';
import CustomSvg from '../componets/Svg/CustomSvg';
import Loading from '../componets/Loading/Loading';
import { get } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    height: calc(100vh - 10rem);
    margin-bottom: 10rem;
`;
const Monitoring = () => {
    const { t } = useTranslation();
    const { data, isLoading } = getMonitoringData();
    const usersCount: any[] = get(data, 'usersCountByAccessLevel', []);
    const services = get(data, 'servicesIdsAndNames', []);

    const total = Array.isArray(usersCount) ? usersCount.reduce((acc, current) => acc + current.value, 0) : 0;
    const [serviceId, setServiceId] = useState<string>('');
    return (
        <Container className='row'>
            <Card>
                {isLoading ? (
                    <Loading />
                ) : (
                    <FlexClm>
                        <p>{t('monitoring.total', { tot: total })}</p>
                        <Divider />
                        <div className='row'>
                            <FlexClm className='col-8'>
                                <p>{t('monitoring.admin', { tot: usersCount.find(d => d.key === AccessLevel.Admin)?.value })}</p>
                                <p>{t('monitoring.serviceManager', { tot: usersCount.find(d => d.key === AccessLevel.ServiceManager)?.value })}</p>
                                <p>{t('monitoring.noPerm', { tot: usersCount.find(d => d.key === AccessLevel.None)?.value })}</p>
                            </FlexClm>
                            <FlexClm className='col-4'>
                                <CustomChart data={usersCount} />
                            </FlexClm>
                        </div>

                        <FlexClm className='row'>
                            <Select
                                options={services.map(s => ({ value: s.key, label: s.value, id: s.key }))}
                                onChange={value => {
                                    setServiceId(value?.value ?? '');
                                }}
                            />
                            {serviceId && <ServiceData serviceId={serviceId} />}
                        </FlexClm>
                    </FlexClm>
                )}
            </Card>
            <div style={{ paddingTop: '3rem' }}></div>
        </Container>
    );
};

const ServiceData = ({ serviceId }: { serviceId: string }) => {
    const { t } = useTranslation();
    const { data, isLoading, isValidating } = getServiceData(serviceId);
    const roleIdentifiers = get(data, 'roleIdentifiers', []);
    const userRolesCountByPowerLevel = get(data, 'userRolesCountByPowerLevel', []);
    const userServicesAssignedCount = get(data, 'userServicesAssignedCount', 0);
    const [roleId, setRoleId] = useState<string>('');
    const users: UsersCountByAccessLevel[] = get(userRolesCountByPowerLevel, `[${roleId}]`, []);

    return (
        <FlexClm>
            <Divider />
            {isLoading || isValidating ? (
                <Loading />
            ) : (
                <>
                    <FlexClm>
                        <p>{t('monitoring.totalService', { tot: userServicesAssignedCount })}</p>

                        <div className='row'>
                            <FlexClm className='col-8'>
                                {roleIdentifiers.map(({ id, name }) => {
                                    const assigned: any[] = get(userRolesCountByPowerLevel, `[${id}]`, []);
                                    return (
                                        <div className='row'>
                                            <p key={id}>{t('monitoring.role', { tot: name })}</p>
                                            <span>{t('monitoring.assigned', { tot: assigned.reduce((acc, cV: any) => acc + cV.value, 0) })}</span>
                                        </div>
                                    );
                                })}
                            </FlexClm>
                        </div>

                        <div className='row'>
                            <Select
                                placeholder={t('generic.inputRole')}
                                options={roleIdentifiers.map(r => ({ value: r.id, label: r.name, id: r.id }))}
                                onChange={value => {
                                    setRoleId(value?.value ?? '');
                                }}
                            />
                        </div>
                    </FlexClm>

                    <Divider />
                    {roleId && users.length && (
                        <FlexClm>
                            <div className='row'>
                                <FlexClm className='col-8'>
                                    <p>{t('monitoring.admin', { tot: users.find(d => d.key === AccessLevel.Admin)?.value }) ?? 0}</p>
                                    <p>{t('monitoring.serviceManager', { tot: users.find(d => d.key === AccessLevel.ServiceManager)?.value }) ?? 0}</p>
                                    <p>{t('monitoring.noPerm', { tot: users.find(d => d.key === AccessLevel.None)?.value }) ?? 0}</p>
                                </FlexClm>
                                <FlexClm className='col-4'>
                                    <CustomChart data={users} />
                                </FlexClm>
                            </div>
                        </FlexClm>
                    )}
                </>
            )}
        </FlexClm>
    );
};

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
                {payload.name}
            </text>
            <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
            <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
            {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' /> */}
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
            {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};

const CustomChart = ({ data }: { data: UsersCountByAccessLevel[] }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation();
    let accessWithLabel = {
        [AccessLevel.None]: t('accessLevel.None'),
        [AccessLevel.ServiceManager]: t('accessLevel.ServiceManager'),
        [AccessLevel.Writer]: t('accessLevel.Writer'),
        [AccessLevel.Admin]: t('accessLevel.Admin'),
    };

    const normalizedData = data.map(userCount => ({ name: get(accessWithLabel, `[${userCount.key}]`, ''), value: userCount.value }));
    return (
        <ResponsiveContainer width='100%' height='100%'>
            <PieChart width={600} height={600}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={normalizedData}
                    cx='50%'
                    cy='50%'
                    innerRadius={60}
                    outerRadius={80}
                    fill='#8884d8'
                    dataKey='value'
                    onMouseEnter={(value, realValue) => setActiveIndex(realValue)}
                />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default Monitoring;
