import { AlertError, AlertSuccess } from '../Alert/Alert';
import { Btn, BtnOutLine } from '../Buttons/Buttons';
import { Button, Card, Spinner } from 'react-bootstrap';
import { ButtonMassiveWrapper, FlexCenter, FlexClm, InfoBox } from '../../style';
import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { IGetUserForRole, IServiceOfUser } from '../../model';
import Select, { StylesConfig, ThemeConfig } from 'react-select';

import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { Formik } from 'formik';
import { Input } from 'design-react-kit';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../UserContext/userContext';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';
import { Table } from 'design-react-kit';
import httpClient from '../../api/httpClient';
import styled from 'styled-components';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

const ButtonStyled = styled.button`
    padding: 0.5rem 0.5rem;
    border-radius: 0 8px 8px 0;
    background-color: #f90;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    color: white;
    border: none;
    max-width: 200px;
    border: 1px solid #f90;
`;

const FormElement = styled.form`
    display: flex;
    align-items: center;
`;

const InputElement = styled.input`
    padding: 0.5rem 0.5rem;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    width: 100%;
    border-color: #f90;
    border: 1px solid #f90;
    box-sizing: border-box;
    font-weight: 600;
    box-shadow: none;

    :active {
        border: 1px solid #f90;
    }

    :focus {
        border: 1px solid #f90;
    }
`;
const MyPaginate = styled(ReactPaginate).attrs({
    // You can redefine classes here, if you want.
    activeClassName: 'active', // default to "selected"
})`
    margin-bottom: 2rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    color: #f90;
    gap: 0.2rem;
    li a {
        border-radius: 4px;
        padding: 0.1rem 1rem;
        border: #f90 1px solid;
        cursor: pointer;
        text-decoration: none;
        color: #f90;
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
    }
    li.active a {
        background-color: #f90;
        border: #f90 1px solid;
        border-color: transparent;
        color: white;
        border-radius: 4px;
    }
    li.disabled a {
        color: grey;
    }
    li.disable,
    li.disabled a {
        cursor: default;
        color: #f90;
    }
`;
const MassiveRoleHandler = ({ service, selectTheme, selectStyles }: { service: IServiceOfUser; selectTheme: ThemeConfig; selectStyles: StylesConfig }) => {
    const [selectedRole, setSelectedRole] = useState<{ label: string; value: string }[]>([]);
    const [total, setTotal] = useState<number>(-1);
    const [result, setResult] = useState<IGetUserForRole[]>([]);
    const [page, setPage] = useState<number>(0);
    const [selected, setSelected] = useState<string[]>([]);
    const [skip, setSkip] = useState<number>(0);
    const [fullChecked, setFullChecked] = useState<boolean>(false);
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const [refresh, setRefresh] = useState<boolean>(false);

    const [removeAllModal, setRemoveAllModal] = useState<boolean>(false);
    const [addRoleToAllModal, setAddRoleToAllModal] = useState<boolean>(false);
    const [addRoleToSelectedModal, setAddRoleToSelectedModal] = useState<boolean>(false);
    const [removeRoleFromSelectedModal, setRemoveRoleFromSelectedModal] = useState<boolean>(false);
    const [addAttributeToSelectedModal, setAddAttributeToSelectedModal] = useState<boolean>(false);

    const { loggedUser } = useContext(LoggedUserContext);
    const records = 20;
    const { t } = useTranslation();
    const { trigger: getUsersForRole } = useSWRMutation('GetUsersForRole', httpClient.postRequest);

    useEffect(() => {
        setSelectedRole([]);
        setTotal(-1);
        setResult([]);
    }, [service]);

    const roles = service.roles;

    const options = roles.map(role => {
        return { value: role.id, label: role.name };
    });

    const handleSelections = (values: any) => {
        setSelectedRole(values);
    };
    const pageCount = Math.ceil(total / records);

    // Invoke when user click to request another page.
    const handlePageClick = async (event: any) => {
        if (loadingPage) return;
        try {
            setLoadingPage(true);
            if (fullChecked) setFullChecked(false);
            if (selected.length > 0) setSelected([]);
            const userRoles = await getUsersForRole({
                ids: [...selectedRole.map(role => role.value)],
                skip: event.selected * records,
                take: records,
                attributeToSearch: value,
            });
            setResult(userRoles.users);
            setTotal(userRoles.total);
            setPage(event.selected);
            const newOffset = (event.selected * records) % total;
            setSkip(newOffset);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingPage(false);
        }
    };

    const fetchData = async () => {
        try {
            const userRoles = await getUsersForRole({
                ids: [...selectedRole.map(role => role.value)],
                skip: page * records,
                take: records,
                attributeToSearch: value,
            });
            setResult(userRoles.users);
            setTotal(userRoles.total);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingPage(false);
        }
    };

    useEffect(() => {
        if (refresh) setRefresh(!refresh);
    }, [refresh]);

    useEffect(() => {
        setRefresh(true);
    }, [service]);

    return (
        <>
            {!refresh && (
                <Select
                    options={options}
                    theme={selectTheme as ThemeConfig}
                    styles={selectStyles}
                    isMulti
                    onChange={values => {
                        if (Array.isArray(values) && values.length > 0) handleSelections(values);
                        else {
                            setTotal(-1);
                            setResult([]);
                        }
                    }}
                />
            )}

            {selectedRole.length > 0 && (
                <Formik
                    initialValues={{ value: '' }}
                    onSubmit={async values => {
                        const userRoles = await getUsersForRole({
                            ids: [...selectedRole.map(role => role.value)],
                            skip: 0,
                            take: records,
                            attributeToSearch: values.value,
                        });
                        setResult(userRoles.users);
                        setTotal(userRoles.total);
                    }}>
                    {({ values, handleSubmit, setFieldValue }) => {
                        return (
                            <FormElement onSubmit={handleSubmit}>
                                <InputElement
                                    name='value'
                                    defaultValue={values.value}
                                    onChange={value => {
                                        setValue(value.target.value);
                                        setFieldValue('value', value.target.value);
                                    }}
                                />
                                <ButtonStyled type='submit'>{t('btns.find')}</ButtonStyled>
                            </FormElement>
                        );
                    }}
                </Formik>
            )}
            {total === 0 && (
                <Card>
                    <InfoBox>{t('table.noResult')}</InfoBox>
                </Card>
            )}
            <div style={{ minHeight: '500px', display: 'flex', flexDirection: 'column' }}>
                {total > 0 && !!result && !!result.length && (
                    <>
                        <Table size='xl' className='table table-striped' responsive>
                            <thead className='complementary-2-bg-b2 text-white'>
                                <tr>
                                    <th scope='col'>
                                        <CustomCheckbox
                                            value={fullChecked}
                                            onChange={value => {
                                                setFullChecked(value.target.checked);
                                                setSelected(value.target.checked ? result.map(r => r.userObjectId) : []);
                                            }}
                                        />
                                    </th>
                                    <th scope='col'>{t('table.clmName')}</th>
                                    <th scope='col'>{t('table.clmDescr')}</th>
                                    <th scope='col'>{t('table.clmService')}</th>
                                    <th scope='col'>{t('table.clmAttr')}</th>
                                    <th scope='col'>{t('table.clmRoles')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {!loadingPage &&
                                    result.map((s: IGetUserForRole) => {
                                        let isSelected = selected.includes(s.userObjectId);
                                        return (
                                            <tr key={s.userObjectId}>
                                                <td scope='col'>
                                                    <CustomCheckbox
                                                        value={isSelected}
                                                        onChange={value => {
                                                            setSelected(value.target.checked ? [s.userObjectId, ...selected] : selected.filter(r => r !== s.userObjectId));
                                                        }}
                                                    />
                                                </td>
                                                <td scope='col'>{s.userName}</td>
                                                <td scope='col'>{s.userSurname}</td>
                                                <td scope='col'>{s.serviceName}</td>
                                                <td scope='col'>
                                                    {Object.keys(s.roleNameAttributesPair)
                                                        .map(d => s.roleNameAttributesPair[d].map(d => d.value).join(', '))
                                                        .join('; ')}
                                                </td>
                                                <td scope='col'>{Object.keys(s.roleNameAttributesPair).join(', ')}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        {loadingPage && <Loading />}
                    </>
                )}
            </div>
            <MyPaginate breakLabel='...' nextLabel='>' forcePage={page} onPageChange={handlePageClick} pageRangeDisplayed={5} pageCount={pageCount} previousLabel='<' renderOnZeroPageCount={null} />
            <ButtonMassiveWrapper>
                <Btn
                    text={t('buttonsMassive.removeAll')}
                    onClick={() => {
                        setRemoveAllModal(true);
                    }}
                />
                <Btn
                    text={t('buttonsMassive.removeFromSelected')}
                    onClick={() => {
                        setRemoveRoleFromSelectedModal(true);
                    }}
                />
                <Btn
                    text={t('buttonsMassive.addAll')}
                    onClick={() => {
                        setAddRoleToAllModal(true);
                    }}
                />
                <Btn
                    text={t('buttonsMassive.addSelected')}
                    onClick={() => {
                        setAddRoleToSelectedModal(true);
                    }}
                />
                <Btn
                    text={t('buttonsMassive.addAttr')}
                    onClick={() => {
                        setAddAttributeToSelectedModal(true);
                    }}
                />
            </ButtonMassiveWrapper>

            <>
                <ModalRemoveRoleFromAll
                    onHide={() => {
                        setRemoveAllModal(false);
                    }}
                    reFetch={fetchData}
                    options={options}
                    show={removeAllModal}
                />
                <ModalRemoveRoleFromSelected
                    onHide={() => {
                        setRemoveRoleFromSelectedModal(false);
                    }}
                    reFetch={fetchData}
                    show={removeRoleFromSelectedModal}
                    options={options}
                    usersIds={selected}
                />
                <ModalAddRoleAllSelected
                    onHide={() => {
                        setAddRoleToSelectedModal(false);
                    }}
                    reFetch={fetchData}
                    options={options}
                    usersIds={selected}
                    show={addRoleToSelectedModal}
                />
                <ModalAddRoleAll
                    onHide={() => {
                        setAddRoleToAllModal(false);
                    }}
                    reFetch={fetchData}
                    options={options}
                    show={addRoleToAllModal}
                />
                <ModalAddAttributeToAll
                    onHide={() => {
                        setAddAttributeToSelectedModal(false);
                    }}
                    reFetch={fetchData}
                    usersIds={selected}
                    options={options}
                    show={addAttributeToSelectedModal}
                />
            </>
        </>
    );
};
interface IModalProps {
    show: boolean;
    onHide: () => void;
    options?: any[];
    reFetch?: () => Promise<void>;
}

interface IModalSelectedsProps extends IModalProps {
    usersIds: string[];
}

const ModalRemoveRoleFromAll = (props: IModalProps) => {
    const { t } = useTranslation();
    const { show, onHide, options, reFetch } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { trigger: triggerRemoveRoleFromAll } = useSWRMutation('RemoveRoleFromAll', httpClient.postRequest);
    const [loading, setLoading] = useState<boolean>(false);
    const [roleIds, setRoleIds] = useState<any>([]);

    const removeRoleFromAll = async () => {
        setLoading(true);
        try {
            await triggerRemoveRoleFromAll({
                roleIds: roleIds.map((r: any) => r.value),
                grantorId: loggedUser?.userObjectId,
            });
            AlertSuccess(t('generic.success'));
            if (!!reFetch) {
                await reFetch();
            }
            onHide();
        } catch (err) {
            AlertError(t('generic.error'));
        } finally {
            setLoading(false);
        }
    };
    let closeModal = !loading ? onHide : () => {};
    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modals.areYouSure')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexClm>
                    {t('modals.selectRole')}
                    <Select
                        options={options}
                        isMulti
                        onChange={values => {
                            setRoleIds(values);
                        }}
                    />
                </FlexClm>
            </Modal.Body>
            <Modal.Footer>
                <BtnOutLine text={t('btns.cancel')} onClick={closeModal} disabled={loading} />
                {loading ? (
                    <Button variant='primary' disabled className='complementary-2-bg-b1'>
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        <span>{t('btns.confirm')}</span>
                    </Button>
                ) : (
                    <Btn
                        text={t('btns.confirm')}
                        onClick={() => {
                            removeRoleFromAll();
                        }}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

const ModalRemoveRoleFromSelected = (props: IModalSelectedsProps) => {
    const { t } = useTranslation();
    const { show, onHide, usersIds, options, reFetch } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { trigger: triggerRemoveRoleFromSelected } = useSWRMutation('RemoveRoleFromSelected', httpClient.postRequest);
    const [loading, setLoading] = useState<boolean>(false);
    const [roleIds, setRoleIds] = useState<any>([]);

    const removeRoleFromSelected = async () => {
        setLoading(true);
        try {
            await triggerRemoveRoleFromSelected({
                roleIds: roleIds.map((r: any) => r.value),
                grantorId: loggedUser?.userObjectId,
                usersIds,
            });
            if (!!reFetch) {
                await reFetch();
            }
            AlertSuccess(t('generic.success'));
            onHide();
        } catch (err) {
            AlertError(t('generic.error'));
        } finally {
            setLoading(false);
        }
    };
    let closeModal = !loading ? onHide : () => {};

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modals.areYouSure')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexClm>
                    {t('modals.selectRoleToSelected')}
                    <Select
                        options={options}
                        isMulti
                        onChange={values => {
                            setRoleIds(values);
                        }}
                    />
                </FlexClm>
            </Modal.Body>
            <Modal.Footer>
                <BtnOutLine text={t('btns.cancel')} onClick={closeModal} disabled={loading} />

                {loading ? (
                    <Button variant='primary' disabled className='complementary-2-bg-b1'>
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        <span>{t('btns.confirm')}</span>
                    </Button>
                ) : (
                    <Btn
                        text={t('btns.confirm')}
                        onClick={() => {
                            removeRoleFromSelected();
                        }}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

const ModalAddRoleAll = (props: IModalProps) => {
    const { t } = useTranslation();
    const { show, onHide, options, reFetch } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { trigger: triggerAddRoleToAllUser } = useSWRMutation('AddRoleToAllUser', httpClient.postRequest);

    const [loading, setLoading] = useState<boolean>(false);
    const [roleIds, setRoleIds] = useState<any>([]);

    const addRoleToAll = async () => {
        setLoading(true);
        try {
            await triggerAddRoleToAllUser({
                roleIds: roleIds.map((r: any) => r.value),
                grantorId: loggedUser?.userObjectId,
            });
            if (!!reFetch) {
                await reFetch();
            }
            AlertSuccess(t('generic.success'));
            onHide();
        } catch (err) {
            AlertError(t('generic.error'));
        } finally {
            setLoading(false);
        }
    };
    let closeModal = !loading ? onHide : () => {};

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modals.areYouSure')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexClm>
                    {t('modals.selectRoleAdd')}
                    <Select
                        options={options}
                        isMulti
                        onChange={values => {
                            setRoleIds(values);
                        }}
                    />
                </FlexClm>
            </Modal.Body>
            <Modal.Footer>
                <BtnOutLine text={t('btns.cancel')} onClick={closeModal} />
                {loading ? (
                    <Button variant='primary' disabled className='complementary-2-bg-b1'>
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        <span>{t('btns.confirm')}</span>
                    </Button>
                ) : (
                    <Btn
                        text={t('btns.confirm')}
                        onClick={() => {
                            addRoleToAll();
                        }}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

const ModalAddRoleAllSelected = (props: IModalSelectedsProps) => {
    const { t } = useTranslation();
    const { show, onHide, usersIds, options, reFetch } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { trigger: triggerAddRoleToSelected } = useSWRMutation('AddRoleToSelected', httpClient.postRequest);
    const [roleIds, setRoleIds] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const addRoleToSelected = async () => {
        setLoading(true);
        try {
            await triggerAddRoleToSelected({
                roleIds: roleIds.map((r: any) => r.value),
                grantorId: loggedUser?.userObjectId,
                usersIds,
            });
            if (!!reFetch) {
                await reFetch();
            }
            AlertSuccess(t('generic.success'));
            onHide();
        } catch (err) {
            AlertError(t('generic.error'));
        } finally {
            setLoading(false);
        }
    };
    let closeModal = !loading ? onHide : () => {};

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modals.areYouSure')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexClm>
                    {t('modals.selectRoleToSelectedAdd')}
                    <Select
                        options={options}
                        isMulti
                        onChange={values => {
                            setRoleIds(values);
                        }}
                    />
                </FlexClm>
            </Modal.Body>
            <Modal.Footer>
                <BtnOutLine text={t('btns.cancel')} onClick={closeModal} />
                {loading ? (
                    <Button variant='primary' disabled className='complementary-2-bg-b1'>
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        <span>{t('btns.confirm')}</span>
                    </Button>
                ) : (
                    <Btn
                        text={t('btns.confirm')}
                        onClick={() => {
                            addRoleToSelected();
                        }}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

const ModalAddAttributeToAll = (props: IModalSelectedsProps) => {
    const { t } = useTranslation();
    const { show, onHide, usersIds, options, reFetch } = props;
    const { trigger: triggerAddAttributeToSelected } = useSWRMutation('AddAttributeToSelected', httpClient.postRequest);
    const [value, setValue] = useState<string>('');
    const [key, setKey] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [roleId, setRoleId] = useState<any>([]);

    const addAttributeToSelected = async () => {
        setLoading(true);
        try {
            if (!!roleId) {
                await triggerAddAttributeToSelected({
                    attributeToAdd: { [key]: value },
                    roleId: roleId.value,
                    userIds: usersIds,
                });
                if (!!reFetch) {
                    await reFetch();
                }
                AlertSuccess(t('generic.success'));
                onHide();
            } else {
                AlertError(t('errors.requiredRole'));
            }
        } catch (err) {
            AlertError(t('generic.error'));
        } finally {
            setLoading(false);
        }
    };
    let closeModal = !loading ? onHide : () => {};

    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modals.createAttr')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexClm>
                    {t('modals.createAttrdedscr')}
                    <Select
                        options={options}
                        onChange={values => {
                            setRoleId(values);
                        }}
                    />
                    <div className='row'>
                        <div className='col-6'>
                            <Input
                                name='key'
                                type='text'
                                label={t('attributes.name')}
                                required
                                id='Key'
                                disabled={loading}
                                placeholder={'Key'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setKey(e.target.value)}
                            />
                        </div>
                        <div className='col-6'>
                            <Input
                                name='value'
                                type='text'
                                label={t('attributes.value')}
                                required
                                id='value'
                                disabled={loading}
                                placeholder={'value'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                            />
                        </div>
                    </div>
                </FlexClm>
            </Modal.Body>
            <Modal.Footer>
                <BtnOutLine text={t('btns.cancel')} onClick={closeModal} />
                {loading ? (
                    <Button variant='primary' disabled className='complementary-2-bg-b1'>
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        <span>{t('btns.confirm')}</span>
                    </Button>
                ) : (
                    <Btn
                        text={t('btns.confirm')}
                        onClick={() => {
                            addAttributeToSelected();
                        }}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default MassiveRoleHandler;
