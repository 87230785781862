import Container from 'react-bootstrap/Container';
import LeftDrawer from '../../layout/LeftDrawer';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';

const ToggleBtn = styled(Navbar.Toggle)`
    border-color: white;
    & span {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
`;
const BurgerNavStyled = styled(Navbar)`
    @media only screen and (min-width: 601px) {
        display: none;
    }
`;
const BurgerNav = () => {
    return (
        <BurgerNavStyled expand={false} className='bg-body-tertiary complementary-2-bg-b1'>
            <Container fluid>
                <ToggleBtn aria-controls={`offcanvasNavbar-expand-${false}`} />
                <Navbar.Offcanvas id={`offcanvasNavbar-expand-${false}`} aria-labelledby={`offcanvasNavbarLabel-expand-${false}`} placement='end'>
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body>
                        <LeftDrawer forMobile />
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </BurgerNavStyled>
    );
};
export default BurgerNav;
