import { RouteObject, createBrowserRouter } from 'react-router-dom';

import { AccessLevel } from '../model/enum';
import AddUser from '../pages/AddUser';
import ErrorBoundary from './ErrorBoundary';
import Home from '../pages/Home';
import Layout from '../layout/Layout';
import { MassiveRoleEditor } from '../pages/MassiveRoleEditor';
import Monitoring from '../pages/Monitoring';
import NotFound from './NotFound';
import ServiceManagement from '../pages/ServiceManagement';
import UserManagement from '../pages/UserManagement';

export const route = {
    notFound: '*',
    home: '/',
    services: 'services',
    users: 'users',
    addUsers: 'addUsers',
    editRole: 'editRole',
    monitoring: 'monitoring',
};

const createRoute = (path: string, element: React.ReactNode) => ({
    path,
    element,
    errorElement: <ErrorBoundary />,
});
const defaultRoute: RouteObject = {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
};

const USER_PATHS = [
    {
        ...defaultRoute,
        children: [createRoute(route.home, <Home />), createRoute(route.notFound, <NotFound />)],
    },
];
const WRITER = [
    {
        ...defaultRoute,
        children: [createRoute(route.home, <Home />), createRoute(route.addUsers, <AddUser />), createRoute(route.users, <UserManagement />), createRoute(route.notFound, <NotFound />)],
    },
];
const SERVICE_MANAGER = [
    {
        ...defaultRoute,
        children: [
            createRoute(route.home, <Home />),
            createRoute(route.addUsers, <AddUser />),
            createRoute(route.services, <ServiceManagement />),
            createRoute(route.users, <UserManagement />),
            createRoute(route.editRole, <MassiveRoleEditor />),
            createRoute(route.notFound, <NotFound />),
        ],
    },
];

const ADMIN_PATH = [
    {
        ...defaultRoute,
        children: [
            createRoute(route.home, <Home />),
            createRoute(route.addUsers, <AddUser />),
            createRoute(route.services, <ServiceManagement />),
            createRoute(route.users, <UserManagement />),
            createRoute(route.editRole, <MassiveRoleEditor />),
            createRoute(route.monitoring, <Monitoring />),
            createRoute(route.notFound, <NotFound />),
        ],
    },
];
const userPth = createBrowserRouter(USER_PATHS);
const adminPath = createBrowserRouter(ADMIN_PATH);
const writer = createBrowserRouter(WRITER);
const serviceManager = createBrowserRouter(SERVICE_MANAGER);

export const routesByAl = {
    [AccessLevel.None]: userPth,
    [AccessLevel.Writer]: writer,
    [AccessLevel.ServiceManager]: serviceManager,
    [AccessLevel.Admin]: adminPath,
};
