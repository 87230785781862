import * as yup from 'yup';

import { AccessLevel, PowerLevel } from '../model/enum';
import AccordionItem, { AccordionWrapper } from '../componets/Accordion/CustomAccordion';
import { AlertError, AlertPromise, AlertSuccess } from '../componets/Alert/Alert';
import { Btn, IconBtn } from '../componets/Buttons/Buttons';
import { Button, Spinner, Table } from 'react-bootstrap';
import { Divider, FlexClm, FlexRow, FormContainer, FormWrapper } from '../style';
import { FieldArray, Formik } from 'formik';
import { IRole, IServiceForUser } from '../model';
import { getNowMinus2Hour, getNowPlus100Year, getNowPlus100YearForCsv, isValidTaxCode, parseDate, validateDates } from '../utils';
import i18n, { ELanguages } from '../localization';

import CustomSelect from '../componets/Select/CustomSelect';
import CustomSvg from '../componets/Svg/CustomSvg';
import { Icon } from 'design-react-kit';
import ScrollToFieldError from '../componets/ScrollToField/ScrollToField';
import { TextInput } from '../componets/Input/Input';
import { get } from 'lodash';
import { getUserServices } from '../api/fetch';
import httpClient from '../api/httpClient';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useCSVReader } from 'react-papaparse';
import useSWRMutation from 'swr/mutation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddUser = () => {
    const { t } = useTranslation();
    const { trigger: createUser } = useSWRMutation(`CreateUser`, httpClient.postRequest);
    const [openItemIndex, setOpenItemIndex] = useState<number | null>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const createUserAsync = async (values: { name: string; surname: string; cf: string; level: string }, resetForm: any) => {
        if (isValidTaxCode(values.cf.toUpperCase())) {
            setLoading(true);
            try {
                const result = await createUser({
                    userObjectId: values.cf,
                    serviceOfUsers: [],
                    accessLevel: parseInt(values.level),
                    userName: values.name,
                    userSurname: values.surname,
                });
                if (result) {
                    AlertSuccess(t('generic.success'));
                    resetForm();
                }
            } catch (err) {
                AlertError(t('errors.createUser'));
            } finally {
                setLoading(false);
            }
        } else {
            AlertError(t('generic.wrongCf'));
        }
    };
    const addUserSchema = yup.object({
        name: yup.string().required(t('errors.fieldRequired')),
        surname: yup.string().required(t('errors.fieldRequired')),
        cf: yup.string().required(t('errors.fieldRequired')),
        level: yup.string().required(t('errors.fieldRequired')),
    });
    const AccessLevelWithlabel = [
        { value: AccessLevel.None, label: t('accessLevel.None'), id: crypto.randomUUID() },
        { value: AccessLevel.Writer, label: t('accessLevel.Writer'), id: crypto.randomUUID() },
        { value: AccessLevel.ServiceManager, label: t('accessLevel.ServiceManager'), id: crypto.randomUUID() },
        { value: AccessLevel.Admin, label: t('accessLevel.Admin'), id: crypto.randomUUID() },
    ];

    const handleToggle = (index: any) => {
        setOpenItemIndex(openItemIndex === index ? null : index);
    };
    return (
        <div className='row'>
            <AccordionWrapper>
                <AccordionItem title={t('generic.addUserSingle')} isOpen={openItemIndex === 0} onClick={() => handleToggle(0)}>
                    <FormWrapper>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={addUserSchema}
                            onSubmit={(values, { resetForm }) => {
                                createUserAsync(values, resetForm);
                            }}
                            initialValues={{
                                name: '',
                                surname: '',
                                cf: '',
                                level: '',
                            }}>
                            {({ handleSubmit, setFieldValue }) => (
                                <FormContainer onSubmit={handleSubmit}>
                                    <ScrollToFieldError />
                                    <div className='row'>
                                        <div className=' col-md-6'>
                                            <TextInput name='name' label={t('generic.name')} />
                                        </div>
                                        <div className='col-md-6'>
                                            <TextInput name='surname' label={t('generic.surname')} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <TextInput name='cf' label={t('generic.cf')} />
                                        </div>
                                        <div className='col-md-6'>
                                            <CustomSelect
                                                name='level'
                                                label={t('generic.accessLevel')}
                                                required
                                                parentOnChange={value => {
                                                    setFieldValue('level', value);
                                                }}
                                                options={AccessLevelWithlabel}
                                            />
                                        </div>
                                    </div>
                                    {loading ? (
                                        <Button variant='primary' disabled className='complementary-2-bg-b1'>
                                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                            <span>{t('btns.loadingCreate')}</span>
                                        </Button>
                                    ) : (
                                        <Btn text={t('btns.create')} type='submit' />
                                    )}
                                </FormContainer>
                            )}
                        </Formik>
                    </FormWrapper>
                </AccordionItem>
                <AccordionItem title={t('generic.addUserMass')} isOpen={openItemIndex === 1} onClick={() => handleToggle(1)}>
                    <MassiveUpload />
                </AccordionItem>
            </AccordionWrapper>
        </div>
    );
};

const StyledLi = styled.li`
    width: 70%;
`;
const StyledLabel = styled.label`
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
    color: #fff;
    min-width: fit-content;
    background-color: #f90;
    border-color: #f90;
    padding: 0.75rem 1.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    justify-content: flex-start;
    & svg {
        margin: 0;
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.2rem !important;
    }
`;

const StyledFilesList = styled.div`
    display: flex;
    vertical-align: middle;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: #cc334d;
    & span {
        align-items: center;
        vertical-align: middle;
        max-width: 375px;
        margin-bottom: 1rem;
        margin-left: 1rem;
    }
`;
interface IUserCsv {
    name: string;
    surname: string;
    cf: string;
    idService: string;
    idRole: string;
    accessLevel: AccessLevel;
    powerLevel: PowerLevel;
    startService: Date;
    endService: Date;
    startRole: Date;
    endRole: Date;
}

const headersByLanguage = {
    [ELanguages.it]: ['nome|cognome|id(codicefiscale)|idservizio|startservizio|endservizio|idruolo|startruolo|endruolo|accessLevel|powerLevel'],
    [ELanguages.en]: ['name|lastname|id(fiscalcode)|serviceId|serviceStart|serviceEnd|roleId|roleStart|roleEnd|accessLevel|powerLevel'],
    [ELanguages.de]: ['Vorname|Nachname|id(Steuernummer)|DienstID|Dienstbeginn|Dienstende|RollenID|Rollenbeginn|Rollende|Zugriffsebene|Leistungsstufe'],
};
const initialFormData = { files: [] };
const MassiveUpload = () => {
    const { t } = useTranslation();
    const { CSVReader } = useCSVReader();
    const [users, setUsers] = useState<IUserCsv[]>([]);
    const { trigger: upload } = useSWRMutation(`CreateUserBulk`, httpClient.postRequest);
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
    const [errorCsv, setErrorCsv] = useState<boolean>(false);
    const [formedCsv, setFormedCsv] = useState<any[]>([]);

    const { services } = getUserServices();
    const realServices = get(services, 'serviceForUserList', []);

    const handleUploadAccepted = (results: any) => {
        setErrorCsv(false);
        const data = get(results, 'data', []);
        if (Array.isArray(data) && data.length > 0) {
            data.shift();
            let rowWithErrors: number[] = [];
            const parsedDataWithError = data.map((row: any, index) => {
                if (isValidTaxCode(`${row[2]}`.trim())) {
                    let startService: string | Date = `${row[4]}`.trim();
                    let endService: string | Date = `${row[5]}`.trim();
                    let startRole: string | Date = `${row[7]}`.trim();
                    let endRole: string | Date = `${row[8]}`.trim();
                    if (!validateDates(startService, endService, startRole, endRole)) {
                        let today = new Date();
                        startService = today;
                        endService = getNowPlus100YearForCsv();
                        startRole = !!row[6] ? today : '';
                        endRole = !!row[6] ? getNowPlus100YearForCsv() : '';
                    }
                    let ac = `${row[9]}`.trim();
                    let pl = `${row[10]}`.trim();
                    return {
                        name: `${row[0]}`.trim(),
                        surname: `${row[1]}`.trim(),
                        cf: `${row[2]}`.trim(),
                        idService: `${row[3]}`.trim(),
                        startService: typeof startService === 'string' ? parseDate(startService) : startService,
                        endService: typeof endService === 'string' ? parseDate(endService) : endService,
                        idRole: `${row[6]}`.trim(),
                        startRole: typeof startRole === 'string' ? parseDate(startRole) : startRole,
                        endRole: typeof endRole === 'string' ? parseDate(endRole) : endRole,
                        accessLevel: isNaN(parseInt(ac)) ? 0 : parseInt(ac),
                        powerLevel: isNaN(parseInt(pl)) ? 0 : parseInt(pl),
                    };
                } else {
                    rowWithErrors.push(index + 1);
                    return undefined;
                }
            });
            if (rowWithErrors.length > 0) {
                toast.error(t('generic.csvError', { data: rowWithErrors.join(', ') }));
            }
            const parsedData = parsedDataWithError.filter(d => !!d);
            const groupedData = parsedData
                .filter((d: any) => !!d && !!d.cf)
                .reduce((acc: any, row: any): any => {
                    if (!acc[row.cf]) {
                        acc[row.cf] = [];
                    }
                    acc[row.cf].push(row);
                    return acc;
                }, {});

            const userArray = Object.keys(groupedData).map(cf => {
                const firstUser = groupedData[cf][0];
                const services = groupedData[cf].map((user: any) => ({ idService: user.idService, endService: user.endService, startService: user.startService }));
                const roles = groupedData[cf].map((user: any) => ({
                    idRole: user.idRole,
                    powerLevel: user.powerLevel,
                    startRole: user.startRole,
                    endRole: user.endRole,
                }));
                return {
                    cf,
                    accessLevel: firstUser.accessLevel,
                    name: firstUser.name,
                    surname: firstUser.surname,
                    services,
                    roles,
                };
            });

            setFormedCsv(userArray);
            setUsers(parsedData.length > 0 ? parsedData.filter((d: any): d is IUserCsv => !!d && !!d.cf) : []);
        } else {
            AlertError(t('generic.csvData'));
        }
    };
    const AccessLevelWithlabel = [
        { value: AccessLevel.None, label: t('accessLevel.None'), id: crypto.randomUUID() },
        { value: AccessLevel.Writer, label: t('accessLevel.Writer'), id: crypto.randomUUID() },
        { value: AccessLevel.ServiceManager, label: t('accessLevel.ServiceManager'), id: crypto.randomUUID() },
        { value: AccessLevel.Admin, label: t('accessLevel.Admin'), id: crypto.randomUUID() },
    ];

    const PowersWithLabel = [
        { value: PowerLevel.None, label: t('powerLevel.none'), id: crypto.randomUUID() },
        { value: PowerLevel.Assign, label: t('powerLevel.assign'), id: crypto.randomUUID() },
        { value: PowerLevel.AssignRecursive, label: t('powerLevel.assignRecursive'), id: crypto.randomUUID() },
    ];

    const findService = (id: string) => {
        if (!!realServices) {
            return realServices.find((s: IServiceForUser) => s.id === id);
        }
        setErrorCsv(true);
        return undefined;
    };

    const findRole = (sId: string, rId: string) => {
        if (!!realServices) {
            const filteredS: IServiceForUser | undefined = realServices.find((s: IServiceForUser) => s.id === sId);
            if (!!filteredS) {
                return filteredS.roles.find(r => r.id === rId);
            } else {
                setErrorCsv(true);
            }
        }
        return undefined;
    };

    const handleUpload = async () => {
        try {
            const payload = formedCsv.map(user => {
                const services = user.services.map((serviceFromUser: { startService: Date; endService: Date; idService: string }) => {
                    const service = findService(serviceFromUser.idService);
                    const roles = user.roles
                        .filter((role: { endRole: Date; startRole: Date; powerLevel: number; idRole: string }) => role.idRole)
                        .map((role: { endRole: Date; startRole: Date; powerLevel: number; idRole: string }) => {
                            const roleDetails = findRole(serviceFromUser.idService, role.idRole);
                            let pL: any = !!roleDetails && role.idRole === roleDetails.id ? roleDetails.powerLevel : role.powerLevel;
                            if (!!roleDetails) {
                                return {
                                    ...roleDetails,
                                    powerLevel: parseInt(pL),
                                    startDate: new Date(role.startRole),
                                    endDate: new Date(role.endRole),
                                };
                            } else {
                                return undefined;
                            }
                        });
                    return {
                        ...service,
                        startDate: serviceFromUser.startService,
                        endDate: serviceFromUser.endService,
                        isVisible: false,
                        roles: roles.filter((r: any) => !!r),
                    };
                });

                return {
                    userObjectId: user.cf,
                    serviceOfUsers: services,
                    accessLevel: parseInt(user.accessLevel),
                    userName: user.name,
                    userSurname: user.surname,
                };
            });
            AlertPromise(t('btns.uploading'), upload(payload), t('generic.success'), t('generic.error'), () => {
                setUsers([]);
            }).finally(() => setLoadingUpload(false));
        } catch (err) {
            AlertError(t('generic.error'));
        }
    };

    const downloadTemplateFile = () => {
        let headers = get(headersByLanguage, `[${i18n.language}]`, headersByLanguage[ELanguages.it]);
        let fileName = 'rm_users_upload.csv';
        let fileType = 'text/csv';
        const blob = new Blob(headers, { type: fileType });

        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    return (
        <>
            <div className='mb-3'>
                <FlexRow style={{ color: 'black' }}>
                    {t('generic.downloadTemplate')}
                    <IconBtn onClick={() => downloadTemplateFile()}>
                        <Icon icon='it-download' color='warning' aria-hidden size='sm' />
                    </IconBtn>
                </FlexRow>
                <Table responsive style={{ color: 'black', marginBottom: '1rem' }}>
                    <caption> {t('generic.csvExample')}</caption>
                    <thead className=''>
                        <tr>
                            <th>{t('csvExample.name')}</th>
                            <th>{t('csvExample.lastname')}</th>
                            <th>{t('csvExample.id')}</th>
                            <th>{t('csvExample.idservizio')}</th>
                            <th>{t('csvExample.startDateService')}</th>
                            <th>{t('csvExample.endDateService')}</th>
                            <th>{t('csvExample.idRuolo')}</th>
                            <th>{t('csvExample.startDateRole')}</th>
                            <th>{t('csvExample.endDateRole')}</th>
                            <th>{t('csvExample.accessLevel')}</th>
                            <th>{t('csvExample.powerLevel')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Giovanni</td>
                            <td>Rossi</td>
                            <td>RSSGNN80A01F499L</td>
                            <td>2ee79bc...</td>
                            <td>24/01/2024</td>
                            <td>24/01/2124</td>
                            <td>33aba9b...</td>
                            <td>24/01/2024</td>
                            <td>24/01/2124</td>
                            <td>1</td>
                            <td>1</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <Formik
                initialValues={initialFormData}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                    if (errorCsv) {
                        AlertError(t('errors.csvError'));
                    } else {
                        handleUpload();
                    }
                }}>
                {({ handleSubmit, values, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FlexClm>
                                <div className='d-flex justify-content-between mb-3'>
                                    <CSVReader onUploadAccepted={handleUploadAccepted}>
                                        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
                                            <FlexClm>
                                                <button {...getRootProps()} className={'btn btn-primary complementary-2-bg-b1'} type={'button'}>
                                                    <CustomSvg className='icon icon-sm mr-2' iconName={'it-file-csv'} white />

                                                    {t('btns.uploadUsers')}
                                                </button>
                                                <ProgressBar />
                                            </FlexClm>
                                        )}
                                    </CSVReader>
                                    <div>
                                        <Button variant='primary' className='complementary-2-bg-b1' disabled={loadingUpload || !users.length} type='submit'>
                                            <CustomSvg className='icon icon-sm mr-2' iconName={'it-upload'} white />
                                            <span className='icon icon-sm mx-2'>{t('btns.upload')}</span>
                                        </Button>
                                    </div>
                                </div>
                                {users.length > 0 && (
                                    <FlexClm className='row' style={{ padding: '0.5rem' }}>
                                        <Divider />
                                        <Table responsive className='table table-striped'>
                                            <thead className='complementary-2-bg-b2 text-white'>
                                                <tr>
                                                    <th scope='col'>{t('table.clmName')}</th>
                                                    <th scope='col'>{t('table.clmSurname')}</th>
                                                    <th scope='col'>{t('table.clmCf')}</th>
                                                    <th scope='col'>{t('table.clmService')}</th>
                                                    <th scope='col'>{t('table.startDateService')}</th>
                                                    <th scope='col'>{t('table.endDateService')}</th>
                                                    <th scope='col'>{t('table.clmRoles')}</th>
                                                    <th scope='col'>{t('table.startDateRole')}</th>
                                                    <th scope='col'>{t('table.endDateRole')}</th>
                                                    <th scope='col'>{t('table.clmAc')}</th>
                                                    <th scope='col'>{t('table.clmPowerLeve')}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {users.map((s: IUserCsv) => {
                                                    let service = findService(s.idService);
                                                    let role = findRole(s.idService, s.idRole);
                                                    let isCfOk = isValidTaxCode(s.cf);
                                                    return (
                                                        <tr key={s.cf + s.idService}>
                                                            <td scope='col'>{s.name}</td>
                                                            <td scope='col'>{s.surname}</td>
                                                            <td scope='col'>{isCfOk ? s.cf : <p style={{ color: 'red' }}>{t('generic.wrongCf')}</p>}</td>
                                                            <td scope='col'>{!!service ? service.name : <p style={{ color: 'red' }}>{t('errors.serviceError')}</p>}</td>
                                                            <td scope='col'>{s.startService.toLocaleDateString()}</td>
                                                            <td scope='col'>{s.endService.toLocaleDateString()}</td>
                                                            <td scope='col'>{!!role ? role.name : ''}</td>
                                                            <td scope='col'>{!!role ? s.startRole.toLocaleDateString() : ''}</td>
                                                            <td scope='col'>{!!role ? s.endRole.toLocaleDateString() : ''}</td>
                                                            <td scope='col'>{get(AccessLevelWithlabel, `[${s.accessLevel}].label`, AccessLevelWithlabel[0].label)}</td>
                                                            <td scope='col'>{get(PowersWithLabel, `[${s.powerLevel}].label`, PowersWithLabel[0].label)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </FlexClm>
                                )}
                            </FlexClm>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default AddUser;
