import { SVGProps } from 'react';
import Sprite from 'bootstrap-italia/dist/svg/sprites.svg';
import styled from 'styled-components';

interface ICustomSvgProps extends SVGProps<SVGSVGElement> {
    iconName: string;
    className?: string;
    white?: boolean;
}
const StyledSvg = styled.svg<{ $white?: boolean }>`
    fill: ${props => (props.$white ? 'white' : '#f90')};
`;
const CustomSvg = ({ iconName, white, className = 'icon d-none d-lg-block', ...other }: ICustomSvgProps) => {
    return (
        <StyledSvg $white={white} className={className} {...other}>
            <use href={`${Sprite}#${iconName}`}></use>
        </StyledSvg>
    );
};

export default CustomSvg;
