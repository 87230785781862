export enum AccessLevel {
    None,
    Writer,
    ServiceManager,
    Admin,
}

export enum PowerLevel
{
    None,
    Assign,
    AssignRecursive
}