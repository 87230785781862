import { AlertError, AlertSuccess } from '../Alert/Alert';
import { FlexCenter, FlexCol, FormContainer } from '../../style';
import { Form, Formik } from 'formik';
import { IRole, IServiceOfUser } from '../../model';
import React, { useEffect } from 'react';
import { getNowMinus2Hour, getNowPlus100Year } from '../../utils';

import { Btn } from '../Buttons/Buttons';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import styled from 'styled-components';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IRoleHandlerProps {
    selectedService: IServiceOfUser;
}

type mode = 'new' | 'edit' | 'list';

const RoleList = styled.ul`
    list-style: none;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
`;

const RoleItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
    :last-child {
        border-bottom: none;
    }
`;

const RoleItemTitle = styled.h3`
    font-size: 1rem;
    margin: 0;
`;

const AddRole = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-weight: 600;
`;

const SelectInput = styled.select`
    padding: 0.5rem;
    width: 100%;
    border: 1px solid #ccc;
    border-left: none;
    border-right: none;
    border-top: none;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
`;

const Button = styled.button`
    padding: 0.8rem 1rem;
    border-radius: 8px;
    background-color: #f90;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    color: white;
    border: none;
`;

const RoleHandler = ({ selectedService, setSelectedService }: any) => {
    const [selectedRole, setSelectedRole] = React.useState<IRole | null>(null);
    const [mode, setMode] = React.useState<mode>('list');
    const [roles, setRoles] = React.useState<IRole[]>(get(selectedService, 'roles', []));
    const { trigger: UpdateService } = useSWRMutation('UpdateService', httpClient.postRequest);
    const { t } = useTranslation();

    const handleDeleteRole = async (id: string) => {
        try {
            const updatedService = {
                ...selectedService,
                roles: selectedService.roles.filter((role: IRole) => role.id !== id),
            };
            const response = await UpdateService(updatedService);
            if (response) {
                AlertSuccess(t('services.deletedRoleOk'));
                setSelectedService(updatedService);
            }
        } catch (error) {
            AlertError(t('services.deleteRoleKO'));
        }
    };

    useEffect(() => {
        setMode('list');
        setRoles(get(selectedService, 'roles', []));
    }, [selectedService]);

    switch (mode) {
        case 'edit':
            return (
                <Formik
                    initialValues={selectedRole as IRole}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        try {
                            const rolesToUpdate = selectedService.roles.map((role: IRole) => {
                                if (role.id === values.id) {
                                    return {
                                        ...role,
                                        name: values.name,
                                        description: values.description,
                                    };
                                } else {
                                    return role;
                                }
                            });
                            const updatedService = {
                                ...selectedService,
                                roles: rolesToUpdate,
                            };
                            setRoles(rolesToUpdate);

                            const response = await UpdateService(updatedService);

                            if (response) {
                                AlertSuccess(t('services.editRoleOK'));
                                setMode('list');
                                resetForm();
                                setSelectedService(updatedService);
                            }
                        } catch (error) {
                            AlertError(t('services.editRoleKO'));
                        }
                    }}>
                    {({ values, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <input type='text' className='form-control' value={values.id} name='id' placeholder='Id' disabled onChange={handleChange} />
                            <input type='text' className='form-control' value={values.name} name='name' placeholder='nome' onChange={handleChange} />
                            <input type='text' className='form-control' value={values.description} name='description' placeholder='nome' onChange={handleChange} />

                            <ButtonWrapper>
                                <Button type='submit'>{t('btns.save')}</Button>
                                <Button type='button' onClick={() => setMode('list')}>
                                    {t('btns.cancel')}
                                </Button>
                                <Button type='button' onClick={() => handleDeleteRole(values.id)}>
                                    {t('btns.delete')}
                                </Button>
                            </ButtonWrapper>
                        </form>
                    )}
                </Formik>
            );
        case 'list':
            return (
                <>
                    <RoleList>
                        {roles.length > 0 ? (
                            roles.map((role: IRole) => (
                                <RoleItem key={role.id}>
                                    <RoleItemTitle>{role.name}</RoleItemTitle>
                                    <Btn
                                        text='Modifica'
                                        onClick={() => {
                                            setSelectedRole(role);
                                            setMode('edit');
                                        }}
                                    />
                                </RoleItem>
                            ))
                        ) : (
                            <AddRole>
                                <p>{t('services.noRoleSelected')}</p>
                                <Button type='button' onClick={() => setMode('new')}>
                                    {t('services.addRole')}
                                </Button>
                            </AddRole>
                        )}
                    </RoleList>
                    {roles.length > 0 && (
                        <Button type='button' onClick={() => setMode('new')}>
                            {t('services.addRole')}
                        </Button>
                    )}
                </>
            );
        case 'new':
            return (
                <Formik
                    initialValues={{ id: crypto.randomUUID(), name: '', description: '' }}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    onSubmit={async values => {
                        try {
                            const rolesToUpdate = [...selectedService.roles, { ...values, startDate: getNowMinus2Hour(), endDate: getNowPlus100Year() }];
                            const updatedService = {
                                ...selectedService,
                                roles: [...rolesToUpdate],
                            };
                            const respose = await UpdateService(updatedService);
                            if (respose) {
                                AlertSuccess(t('services.addRoleOk'));
                                setMode('list');
                                setRoles(rolesToUpdate);
                                setSelectedService(updatedService);
                            }
                        } catch (error) {
                            AlertError(t('services.addRoleKO'));
                        }
                    }}>
                    {({ values, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <input type='text' className='form-control' value={values.id} placeholder='id' name='id' disabled onChange={handleChange} />
                            <input type='text' className='form-control' value={values.name} name='name' placeholder='Nome ruolo' onChange={handleChange} />
                            <input type='text' className='form-control' value={values.description} name='description' placeholder='Descrizione ruolo' onChange={handleChange} />
                            <ButtonWrapper>
                                <Button type='submit'>{t('btns.save')}</Button>
                                <Button type='button' onClick={() => setMode('list')}>
                                    {t('btns.cancel')}
                                </Button>
                            </ButtonWrapper>
                        </Form>
                    )}
                </Formik>
            );
        default:
            return <></>;
    }
};
export default RoleHandler;
