import * as yup from 'yup';

import { AccessLevel, PowerLevel } from '../model/enum';
import { AlertError, AlertPromise, AlertSuccess } from '../componets/Alert/Alert';
import { Btn, BtnOutLine, IconBtn, UnderlinedBtn } from '../componets/Buttons/Buttons';
import { Button, InputGroup } from 'react-bootstrap';
import { Card, Divider, FlexClm, InfoBox, PaginationWrapper } from '../style';
import { ChangeEvent, FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray, Formik, useFormikContext } from 'formik';
import { IAttribute, ICustomSelectOption, IGetUserBlob, IRole, ISearchUser, IServiceForUser, IServiceOfUser } from '../model';
import { IconButton, IconButtonUnderlined } from '../style/buttons/buttons';
import Select, { MultiValue, SingleValue } from 'react-select';
import { cryptData, getNowMinus2Hour, getNowPlus100Year, isAdmin, isServiceManager, isValidTaxCode, isWriter } from '../utils';

import CustomModal from '../componets/Modal/CustomModal';
import CustomSelect from '../componets/Select/CustomSelect';
import CustomSvg from '../componets/Svg/CustomSvg';
import ErrorText from '../componets/ErrorText/ErrorText';
import { Icon } from 'design-react-kit';
import Loading from '../componets/Loading/Loading';
import { LoggedUserContext } from '../componets/UserContext/userContext';
import ScrollToFieldError from '../componets/ScrollToField/ScrollToField';
import { TextInput } from '../componets/Input/Input';
import { addDays } from 'date-fns';
import { get } from 'lodash';
import { getUserServices } from '../api/fetch';
import httpClient from '../api/httpClient';
import makeAnimated from 'react-select/animated';
import styled from 'styled-components';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

interface IFormikUserManagement {
    service: string;
    serviceStart: string;
    serviceEnd: string;
    canModifyService: boolean;
    roles: IRole[];
}

let formikInitialValue: IFormikUserManagement = {
    service: '',
    serviceStart: '',
    serviceEnd: '',
    canModifyService: false,
    roles: [],
};

const UserManagementWrapper = styled.div`
    .form-group {
        margin-top: 1rem;
        margin-bottom: 1rem !important;
    }
`;

const WrapperLabel = styled.div`
    label {
        z-index: 20 !important;
    }
`;
const LevelWithBtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
`;
const StyledSelect = styled(Select)`
    width: 100%;
`;

const Floppy = () => {
    // const { customColors } = useTheme();
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill={'#f90'} className='bi bi-floppy' viewBox='0 0 16 16'>
            <path d='M11 2H9v3h2z' />
            <path d='M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z' />
        </svg>
    );
};

const UserManagement = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const [cf, setCf] = useState('');
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [loadingRemove, setLoadingRemove] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const { loggedUser } = useContext(LoggedUserContext);
    const [addServices, setAddServices] = useState<boolean>(false);
    const [openModalLevel, setOpenModalLevel] = useState<boolean>(false);
    const [loadingModifyLevel, setLoadingModifyLevel] = useState<boolean>(false);

    const [searchedUserBlob, setSearchedUserBlob] = useState<IGetUserBlob | undefined>(undefined);
    const [level, setLevel] = useState<AccessLevel>(searchedUserBlob?.accessLevel ?? -1);
    const services = get(searchedUserBlob, 'serviceOfUsers', []);

    const isadmin = isAdmin(loggedUser?.accessLevel);
    const iswriter = isWriter(loggedUser?.accessLevel);
    const issm = isServiceManager(loggedUser?.accessLevel);

    const { services: servicesFromAPI } = getUserServices(issm ? loggedUser?.userObjectId : undefined, iswriter);

    const { trigger: triggerSearch } = useSWRMutation('SearchUser', httpClient.postRequest);
    const { trigger: updateUser } = useSWRMutation('UpdateUserInBlob', httpClient.postRequest);
    const { trigger: updateAL } = useSWRMutation('UpdateUserAccessLevel', httpClient.postRequest);

    const toggleFocus = () => {
        setIsFocused(true);
        setError(t(''));
    };

    const addUserSchema = yup.object({
        service: yup.string().required(t('errors.fieldRequired')),
        serviceStart: yup.date(),
        serviceEnd: yup
            .date()
            .nullable()
            .test('is-after-tomorrow', t('errors.minDateTomorrow'), value => {
                const tomorrow = addDays(new Date(), 1);
                return !value || (!!value && value >= tomorrow);
            }),
        roles: yup
            .array()
            .of(
                yup.object().shape({
                    startDate: yup.date().required(t('errors.fieldRequired')),
                    endDate: yup
                        .date()
                        .min(yup.ref('startDate'), t('errors.endDate'))
                        .required(t('errors.fieldRequired'))
                        .test('is-after-service-end', t('errors.endDateBeforeServiceEnd'), (value, context) => {
                            const serviceEnd = get(context, 'options.context.serviceEnd');
                            if (typeof serviceEnd === 'string' && typeof value === 'object') {
                                return new Date(value.getFullYear(), value.getMonth(), value.getDate()) < new Date(serviceEnd.split('T')[0]);
                            }
                            if (typeof serviceEnd === 'string' && typeof value === 'string') {
                                return new Date(`${value}`.split('T')[0]) < new Date(serviceEnd.split('T')[0]);
                            }
                            if (typeof serviceEnd === 'object' && typeof value === 'string') {
                                return new Date(`${value}`.split('T')[0]) < serviceEnd;
                            }
                            return value < serviceEnd;
                        }),
                }),
            )
            .required('Required'),
    });
    // @ts-ignore: ignore types
    const toggleBlurLabel = e => {
        if (e.target.value === '') {
            setIsFocused(!isFocused);
            setError(t(''));
        }
    };

    const fetchData = async () => {
        setLoading(true);
        setIsEmpty(false);
        try {
            const userData: ISearchUser = await triggerSearch({
                filter: {},
                extensionFilter: {
                    fiscalNumber: cf,
                },
                select: [''],
                extensionSelect: [''],
            });
            if (!userData.value.length) {
                const fetchedUser: IGetUserBlob = await httpClient.fetcher(`getUserBlob?${cryptData(`userId=${cf}`, true)}`);
                if (!fetchedUser) setIsEmpty(true);
                else {
                    setSearchedUserBlob(fetchedUser);
                }
            } else {
                const fetchedUser: IGetUserBlob = await httpClient.fetcher(`getUserBlob?${cryptData(`userId=${userData.value[0].id}`, true)}`);
                if (!fetchedUser) setIsEmpty(true);
                else {
                    setSearchedUserBlob(fetchedUser);
                }
            }
        } catch (err) {
            AlertError(t('errors.searchUser'));
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isValidTaxCode(cf)) {
            fetchData();
        } else {
            setError(t('generic.wrongCf'));
        }
    };

    const AccessLevelWithlabel = useMemo(() => {
        return [
            { value: AccessLevel.None, label: t('accessLevel.None'), id: crypto.randomUUID() },
            { value: AccessLevel.Writer, label: t('accessLevel.Writer'), id: crypto.randomUUID() },
            { value: AccessLevel.ServiceManager, label: t('accessLevel.ServiceManager'), id: crypto.randomUUID(), disabled: iswriter },
            { value: AccessLevel.Admin, label: t('accessLevel.Admin'), id: crypto.randomUUID(), disabled: iswriter || issm },
        ];
    }, [t]);

    const PowersWithLabel = [
        { value: PowerLevel.None, label: t('powerLevel.none'), id: crypto.randomUUID() },
        { value: PowerLevel.Assign, label: t('powerLevel.assign'), id: crypto.randomUUID() },
        { value: PowerLevel.AssignRecursive, label: t('powerLevel.assignRecursive'), id: crypto.randomUUID() },
    ];
    const PowersWithLabelRecursive = [
        { value: PowerLevel.None, label: t('powerLevel.none'), id: crypto.randomUUID() },
        { value: PowerLevel.Assign, label: t('powerLevel.assign'), id: crypto.randomUUID() },
    ];
    const PowersWithLabelOnlyNone = [{ value: PowerLevel.None, label: t('powerLevel.none'), id: crypto.randomUUID() }];
    const withError = !!error ? 'is-invalid ' : '';
    let className = isFocused ? `${withError} form-control focus--mouse` : `${withError} form-control`;

    const removeRoleFromSelected = async (id: string) => {
        setLoadingRemove(true);
        if (!!searchedUserBlob) {
            const userfiltered = searchedUserBlob.serviceOfUsers.filter(s => s.id !== id);
            AlertPromise(t('generic.editOingoing'), updateUser({ ...searchedUserBlob, serviceOfUsers: userfiltered }), t('generic.success'), t('generic.error'), () => {
                setSearchedUserBlob({ ...searchedUserBlob, serviceOfUsers: [...userfiltered] });
            }).finally(() => setLoadingRemove(false));
        } else {
            AlertError(t('error.generic'));
        }
    };

    const submitServices = async (values: IFormikUserManagement) => {
        const userServiceForWriter = get(loggedUser, 'serviceOfUsers', []);
        const serviteToUse = iswriter ? userServiceForWriter : servicesFromAPI.serviceForUserList;
        const findService: IServiceOfUser | undefined = !!serviteToUse && serviteToUse.find((s: IServiceOfUser) => s.id === values.service);
        if (!!findService && searchedUserBlob) {
            setLoadingUpdate(true);
            let newService: IServiceOfUser = {
                ...findService,
                isVisible: values.canModifyService,
                roles: values.roles.map(r => ({ ...r, powerLevel: parseInt(r.powerLevel as unknown as string) })),
                startDate: !!values.serviceStart ? values.serviceStart : getNowMinus2Hour(),
                endDate: !!values.serviceEnd ? values.serviceEnd : getNowPlus100Year(),
            };

            AlertPromise(
                t('generic.editOingoing'),
                updateUser({ ...searchedUserBlob, serviceOfUsers: [{ ...newService }, ...searchedUserBlob.serviceOfUsers.filter(s => s.id !== findService.id)] }),
                t('generic.success'),
                t('generic.error'),
                () => {
                    setSearchedUserBlob({ ...searchedUserBlob, serviceOfUsers: [{ ...newService }, ...searchedUserBlob.serviceOfUsers.filter(s => s.id !== findService.id)] });
                },
            ).finally(() => setLoadingUpdate(false));
        }
    };

    const servicesToAssign: IServiceForUser[] = useMemo(() => {
        if (isadmin) return get(servicesFromAPI, 'serviceForUserList', []);
        const dataFromMyServices: IServiceForUser[] = get(loggedUser, 'serviceOfUsers', []);
        const sFromApi: IServiceForUser[] = get(servicesFromAPI, 'serviceForUserList', []);
        const servicesNotFromApi = dataFromMyServices.filter(s => iswriter || (issm && !s.isVisible));
        const roleIcanModify = servicesNotFromApi.filter(s => s.roles.filter(r => r.powerLevel > 0).length > 0);

        if (roleIcanModify.length > 0) {
            return [...roleIcanModify, ...sFromApi];
        } else if (sFromApi.length > 0) {
            return [...sFromApi];
        }
        return [];
    }, [servicesFromAPI, loggedUser, isadmin]);

    const getRole = (serviceId: string) => {
        let filteredRole: IServiceForUser | undefined = servicesToAssign.find((s: IServiceForUser) => s.id === serviceId);
        if (!!filteredRole) {
            return filteredRole.roles.map((role: IRole) => ({ value: role.id, label: role.name, id: role.id, data: role })) as ICustomSelectOption[];
        }
        return [] as ICustomSelectOption[];
    };

    const getPowerWithLabels = (serviceId: string, role: string) => {
        const dataFromMyServices: IServiceForUser[] = get(loggedUser, 'serviceOfUsers', []);
        let filteredService: IServiceForUser | undefined = dataFromMyServices.find((s: IServiceForUser) => s.id === serviceId);
        if (isadmin) {
            return PowersWithLabel;
        }
        if (issm) {
            if (filteredService?.isVisible) {
                return PowersWithLabel;
            } else {
                let filteredRole: IRole | undefined = !!filteredService ? filteredService?.roles.find((s: IRole) => s.id === role) : undefined;
                if (filteredRole && filteredRole.powerLevel === PowerLevel.AssignRecursive) {
                    return PowersWithLabelRecursive;
                }
                if (filteredRole && filteredRole.powerLevel === PowerLevel.Assign) {
                    return PowersWithLabelOnlyNone;
                }
            }
        }
        if (iswriter) {
            let filteredRole: IRole | undefined = !!filteredService ? filteredService?.roles.find((s: IRole) => s.id === role) : undefined;
            if (filteredRole && filteredRole.powerLevel === PowerLevel.AssignRecursive) {
                return PowersWithLabelRecursive;
            }
            if (filteredRole && filteredRole.powerLevel === PowerLevel.Assign) {
                return PowersWithLabelOnlyNone;
            }
        }
        return [];
    };
    useEffect(() => {
        if (refresh) setRefresh(!refresh);
    }, [refresh]);

    return (
        <div className='row'>
            <div className='col-8 form-group mt-4'>
                <form onSubmit={onSubmit}>
                    <WrapperLabel className='input-group'>
                        <span className='input-group-text'>
                            <Icon icon='it-search' color='primary' aria-hidden size='sm' />
                        </span>
                        <label htmlFor='cf' className={isFocused ? 'active' : ''}>
                            {t('generic.cf') + '*'}
                        </label>
                        <input
                            type='text'
                            className={className}
                            onFocus={toggleFocus}
                            value={cf}
                            onBlur={toggleBlurLabel}
                            id='cf'
                            name='cf'
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setCf(e.target.value.toUpperCase().trim())}
                            required
                        />
                        <div className='input-group-append'>
                            <Btn type='submit' id='button-3' text={t('btns.find')}></Btn>
                        </div>
                    </WrapperLabel>
                </form>
                {!!error && <ErrorText text={error} />}
            </div>

            <div className='row'>
                <div className='col-12'>
                    {loading && <Loading />}
                    {isEmpty && (
                        <Card>
                            <InfoBox>{t('table.noResult')}</InfoBox>
                        </Card>
                    )}
                    {!!searchedUserBlob && !loading && (
                        <FlexClm>
                            <p>
                                {t('users.editing')}
                                <strong>{!!searchedUserBlob ? ` ${searchedUserBlob.userName} ${searchedUserBlob.userSurname}` : ''}</strong>
                            </p>
                            <p>{t('users.selectAut')}</p>
                            <LevelWithBtnContainer>
                                <StyledSelect
                                    defaultValue={AccessLevelWithlabel.find(data => data.value === searchedUserBlob.accessLevel)}
                                    onChange={(newValue: SingleValue<any>) => {
                                        setLevel(newValue.value as AccessLevel);
                                    }}
                                    options={AccessLevelWithlabel.map(al => ({ value: al.value, label: al.label, id: al.id, isDisabled: al.disabled }))}
                                />

                                <IconButtonUnderlined
                                    type='button'
                                    onClick={() => setOpenModalLevel(true)}
                                    style={{ border: '1px solid #f90', borderRadius: '4px', padding: '0px 4px', height: '37px', width: '40px' }}>
                                    <Floppy />
                                </IconButtonUnderlined>
                            </LevelWithBtnContainer>
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                validationSchema={addUserSchema}
                                onSubmit={(values: IFormikUserManagement) => {
                                    submitServices(values);
                                }}
                                initialValues={
                                    {
                                        ...formikInitialValue,
                                    } as IFormikUserManagement
                                }>
                                {({ values, setFieldValue, handleChange, handleSubmit, errors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <ScrollToFieldError />
                                        <FlexClm className='col-12 '>
                                            {addServices && (
                                                <>
                                                    <Divider />
                                                    <Card>
                                                        <FlexClm className='row'>
                                                            <p>{t('users.selectService')}</p>

                                                            <Select
                                                                name='service'
                                                                placeholder={t('users.selectService')}
                                                                onChange={(data: SingleValue<{ value: string; label: string; id: string }>) => {
                                                                    setFieldValue('service', !!data ? data.value : 0);

                                                                    const foundService = !!data && servicesToAssign.find(s => s.id === data.id);
                                                                    const services = get(searchedUserBlob, 'serviceOfUsers', []);
                                                                    if (foundService) {
                                                                        const servFromBlob = services.find(data => data.id === foundService.id);
                                                                        setFieldValue('serviceStart', foundService.startDate);
                                                                        setFieldValue('serviceEnd', foundService.endDate);
                                                                        setRefresh(true);
                                                                        if (!!servFromBlob) {
                                                                            setFieldValue('roles', get(servFromBlob, 'roles', []));
                                                                        } else {
                                                                            setFieldValue('roles', []);
                                                                        }
                                                                    }
                                                                }}
                                                                options={
                                                                    !!servicesToAssign && servicesToAssign.length > 0
                                                                        ? servicesToAssign.map((s: IServiceForUser) => ({ value: s.id, label: s.name, id: s.id }))
                                                                        : []
                                                                }
                                                            />

                                                            <PaginationWrapper>
                                                                <label htmlFor='can-modify'>{t('generic.canModify')}</label>
                                                                <InputGroup.Checkbox id='can-modify' name='canModifyService' aria-label='can modify' onChange={handleChange} />
                                                            </PaginationWrapper>
                                                            <div className='row'>
                                                                <p>{t('users.selectInt')}</p>
                                                                <UserManagementWrapper className='col-6'>
                                                                    <TextInput type={'date'} name='serviceStart' label={t('users.startDate')} />
                                                                </UserManagementWrapper>
                                                                <UserManagementWrapper className='col-6'>
                                                                    <TextInput type={'date'} name='serviceEnd' label={t('users.endDate')} />
                                                                </UserManagementWrapper>
                                                            </div>
                                                            {!refresh && values.service && getRole(values.service).length > 0 && (
                                                                <Card>
                                                                    <FlexClm>
                                                                        <div className='row'>
                                                                            <p>{t('users.selectRole')}</p>
                                                                            <Select
                                                                                closeMenuOnSelect={false}
                                                                                defaultValue={values.roles.map((role: IRole) => ({ value: role.id, label: role.name, id: role.id, data: role }))}
                                                                                onChange={(newValue: MultiValue<any>) => {
                                                                                    const idRoles = newValue.map(v => v.value);
                                                                                    const service = servicesToAssign.find(s => s.id === values.service);
                                                                                    const filteredRoles = !!service ? service.roles.filter(r => idRoles.includes(r.id)) : [];
                                                                                    setFieldValue('roles', filteredRoles);
                                                                                }}
                                                                                components={animatedComponents}
                                                                                isMulti
                                                                                options={getRole(values.service) as any}
                                                                            />
                                                                        </div>
                                                                        <FieldArray name='roles'>
                                                                            {() => (
                                                                                <FlexClm>
                                                                                    {values.roles.length > 0 &&
                                                                                        values.roles.map((role: IRole, index) => (
                                                                                            <FlexClm key={role.id}>
                                                                                                <Divider />
                                                                                                <FlexClm className='row'>
                                                                                                    <div className='row'>
                                                                                                        <p>{t('users.selectIntRuolo', { role: role.name })}</p>
                                                                                                        <UserManagementWrapper className='col-6'>
                                                                                                            <TextInput type={'date'} name={`roles.${index}.startDate`} label={t('users.startDate')} />
                                                                                                        </UserManagementWrapper>
                                                                                                        <UserManagementWrapper className='col-6'>
                                                                                                            <TextInput type={'date'} name={`roles.${index}.endDate`} label={t('users.endDate')} />
                                                                                                        </UserManagementWrapper>
                                                                                                    </div>
                                                                                                    <div className='row'>
                                                                                                        <CustomSelect
                                                                                                            name={`roles.${index}.powerLevel`}
                                                                                                            label={t('generic.inputRolePower', { role: role.name })}
                                                                                                            wrapperClassName='select-margin'
                                                                                                            options={getPowerWithLabels(values.service, role.id)}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <AttributesArray indexRole={index} />
                                                                                                </FlexClm>
                                                                                            </FlexClm>
                                                                                        ))}
                                                                                </FlexClm>
                                                                            )}
                                                                        </FieldArray>
                                                                    </FlexClm>
                                                                </Card>
                                                            )}
                                                        </FlexClm>
                                                    </Card>
                                                </>
                                            )}
                                            <div>
                                                {!addServices && <Btn type='button' text={t('generic.addServices')} onClick={() => setAddServices(true)}></Btn>}

                                                {addServices && <Btn type='submit' text={t('generic.saveUser')} disabled={loadingUpdate} />}
                                            </div>
                                            <table className='table table-striped'>
                                                <thead className='complementary-2-bg-b2 text-white'>
                                                    <tr>
                                                        <th scope='col'>{t('table.clmName')}</th>
                                                        <th scope='col'>{t('table.clmDescr')}</th>
                                                        <th scope='col'>{t('table.clmroleLeve')}</th>
                                                        <th scope='col'>{t('table.clmRoles')}</th>
                                                        <th scope='col'>{t('table.clmAttr')}</th>
                                                        <th scope='col'>{t('table.clmDelete')}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {services.map((s: IServiceOfUser) => {
                                                        return (
                                                            <tr key={s.id}>
                                                                <td scope='col'>{s.name}</td>
                                                                <td scope='col'>{s.description ?? ''}</td>
                                                                <td scope='col'>{s.roles.length > 0 ? s.roles.map(r => PowersWithLabel.find(a => a.value === r.powerLevel)?.label).join(', ') : ''}</td>
                                                                <td scope='col'>{s.roles.length > 0 ? s.roles.map(r => r.name).join(', ') : ''}</td>
                                                                <td scope='col'>{s.roles.length > 0 ? s.roles.map(r => r.attributes.map(a => `${a.key}:${a.value}`).join(', ')) : ''}</td>
                                                                <td scope='col'>
                                                                    <IconBtn
                                                                        disabled={loadingRemove}
                                                                        onClick={() => {
                                                                            removeRoleFromSelected(s.id);
                                                                        }}>
                                                                        <Icon icon='it-delete' color='warning' aria-hidden size='sm' />
                                                                    </IconBtn>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </FlexClm>
                                    </form>
                                )}
                            </Formik>
                        </FlexClm>
                    )}
                </div>
            </div>
            <CustomModal
                title={t('modals.deleteTitle')}
                disabled={loadingModifyLevel}
                description={t('modals.editLevel')}
                handleConfirm={async () => {
                    setLoadingModifyLevel(true);
                    try {
                        if (!!searchedUserBlob) {
                            let accessLevel = level > -1 ? level : searchedUserBlob.accessLevel;

                            let result = await updateAL({ userId: searchedUserBlob.userObjectId, accessLevel: accessLevel });
                            if (result) {
                                AlertSuccess(t('generic.editSuccess'));
                                if (loggedUser?.userObjectId === searchedUserBlob.userObjectId && loggedUser?.accessLevel !== accessLevel) {
                                    window.location.reload();
                                }
                                setSearchedUserBlob({ ...searchedUserBlob, accessLevel: accessLevel });
                                setOpenModalLevel(false);
                            } else {
                                AlertError(t('generic.editError'));
                            }
                        }
                    } catch (error) {
                        AlertError(t('generic.editError'));
                    } finally {
                        setLoadingModifyLevel(false);
                    }
                }}
                show={openModalLevel}
                handleClose={() => setOpenModalLevel(false)}
            />
        </div>
    );
};

const AttributesArray = ({ indexRole }: { indexRole: number }) => {
    const { values } = useFormikContext();
    const { t } = useTranslation();

    return (
        <FieldArray name={`roles.${indexRole}.attributes`}>
            {({ insert, remove, push }) => {
                const attributes = get(values, `roles.${indexRole}.attributes`, []);
                return (
                    <>
                        <strong>{t('attributes.title')}</strong>
                        <Card>
                            <FlexClm>
                                {attributes.length > 0 &&
                                    attributes.map((attribute: IAttribute, index) => (
                                        <FlexClm className='row' key={attribute.id ?? index}>
                                            <div className='row'>
                                                <UserManagementWrapper className='col-5'>
                                                    <TextInput type='text' name={`roles.${indexRole}.attributes.${index}.key`} label={t('attributes.name')} />
                                                </UserManagementWrapper>
                                                <UserManagementWrapper className='col-5'>
                                                    <TextInput type='text' name={`roles.${indexRole}.attributes.${index}.value`} label={t('attributes.value')} />
                                                </UserManagementWrapper>
                                                <div className='col-2'>
                                                    <IconBtn onClick={() => remove(index)}>
                                                        <Icon icon='it-delete' color='warning' aria-hidden size='sm' />
                                                    </IconBtn>
                                                </div>
                                            </div>
                                        </FlexClm>
                                    ))}
                                <div>
                                    <Btn text={t('attributes.add')} onClick={() => push({ name: '', value: '', id: crypto.randomUUID() })} />
                                </div>
                            </FlexClm>
                        </Card>
                    </>
                );
            }}
        </FieldArray>
    );
};

export default UserManagement;
