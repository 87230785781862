import { ChangeEvent, useId } from 'react';

import ErrorText from '../ErrorText/ErrorText';
import { Input } from 'design-react-kit';
import { format } from 'date-fns';
import { get } from 'lodash';
import { useFormikContext } from 'formik';

interface ITextInputProps {
    name: string;
    label: string;
    readOnly?: boolean;
    required?: boolean;
    description?: string;
    disabled?: boolean;
    type?: any;
}

export const TextInput = (props: ITextInputProps) => {
    const { values, setFieldValue, errors } = useFormikContext();
    const { name, label, readOnly, required, description, disabled, type } = props;
    const id = useId();
    const value = get(values, `[${name}]`);
    const error = get(errors, `[${name}]`);
    let className = !!value ? 'active ' : '';
    let date = '';
    if (type === 'date' && !!value) {
        date = format(value, 'yyyy-MM-dd');
    }
    return (
        <div>
            <Input
                name={name}
                type={type}
                label={label}
                required={required}
                readOnly={readOnly}
                id={id}
                disabled={disabled}
                placeholder={label}
                value={!!date ? date : value}
                className={!!error ? 'is-invalid ' + className : className}
                infoText={description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)}
            />
            {!!error && <ErrorText text={error} />}
        </div>
    );
};
