import * as React from 'react';

import CustomSvg from '../componets/Svg/CustomSvg';
import { Link } from 'react-router-dom';
import { ToastWrapper } from '../style';
import { route } from '.';
import { useTranslation } from 'react-i18next';

interface INotFoundProps {}

const NotFound = (props: INotFoundProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <ToastWrapper>
                <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
            </ToastWrapper>
            <ToastWrapper>
                <blockquote className='blockquote'>
                    <p className='mb-0'>{t('generic.notFound')}</p>
                    <Link to={route.home}>{t('generic.backToHome')}</Link>
                </blockquote>
            </ToastWrapper>
        </div>
    );
};

export default NotFound;
