import { Container, FlexCenter, FormLoading, FormWrapper } from '../style';
import { IGetUser, IServiceOfUser } from '../model';
import { useEffect, useState } from 'react';

import { AlertPromise } from '../componets/Alert/Alert';
import { Button } from 'react-bootstrap';
import CustomSvg from '../componets/Svg/CustomSvg';
import { Formik } from 'formik';
import Loading from '../componets/Loading/Loading';
import { TextInput } from '../componets/Input/Input';
import { cryptData } from '../utils';
import { get } from 'lodash';
import { getUserBlob } from '../api/fetch';
import httpClient from '../api/httpClient';
import { useMsal } from '@azure/msal-react';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IHomeProps {}
const Home = (props: IHomeProps) => {
    return (
        <Container>
            <FormUser />
            <BlobTable />
        </Container>
    );
};

const FormUser = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { trigger: getUser } = useSWRMutation(`GetUser?${cryptData(`userId=${activeAccount?.localAccountId}`, true)}`, httpClient.postRequest);
    const { trigger: updateUser } = useSWRMutation(`UpdateUser`, httpClient.postRequest);

    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [user, setUser] = useState<IGetUser | undefined>(undefined);

    const cf = get(activeAccount, 'idTokenClaims.extension_fiscalNumber', '');

    const fetcData = async () => {
        try {
            const userData: IGetUser = await getUser({
                filter: {},
                extensionFilter: {},
                select: [],
                extensionSelect: [],
            });

            setUser(userData);
        } catch (err) {}
    };
    useEffect(() => {
        fetcData();
    }, []);

    const onSubmit = (values: any) => {
        setLoadingUpdate(true);
        AlertPromise(
            t('generic.editOingoing'),
            updateUser({
                ...user,
                streetAddress: values.address ? values.address : null,
                city: values.city ? values.city : null,
                state: values.state ? values.state : null,
                mobilePhone: values.phone ? values.phone : null,
            }),
            t('generic.success'),
            t('generic.error'),
            () => {},
        ).finally(() => setLoadingUpdate(false));
    };

    return (
        <FormWrapper>
            {!user ? (
                <FormLoading>
                    <Loading />
                </FormLoading>
            ) : (
                <Formik
                    enableReinitialize={true}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={values => {
                        onSubmit(values);
                    }}
                    initialValues={{
                        name: user.givenName,
                        surname: user.surname,
                        city: user.city ?? '',
                        state: user.state ?? '',
                        address: user.streetAddress ?? '',
                        cf: cf,
                        phone: user.mobilePhone ?? '',
                    }}>
                    {({ handleSubmit, setFieldValue, errors }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className='row'>
                                    <div className=' col-md-6'>
                                        <TextInput name='name' label={t('generic.name')} disabled />
                                    </div>
                                    <div className='col-md-6'>
                                        <TextInput name='surname' label={t('generic.surname')} disabled />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <TextInput name='city' label={t('generic.city')} />
                                    </div>
                                    <div className='col-md-6'>
                                        <TextInput name='state' label={t('generic.state')} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <TextInput name='address' label={t('generic.address')} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <TextInput name='cf' label={t('generic.cf')} disabled />
                                    </div>

                                    <div className='col-md-6'>
                                        <TextInput name='phone' label={t('generic.phone')} />
                                    </div>
                                </div>
                                <Button variant='primary' className='complementary-2-bg-b1' disabled={loadingUpdate} type='submit'>
                                    <CustomSvg className='icon icon-sm mr-2' iconName={'it-pencil'} white />
                                    <span className='icon icon-sm mx-2'>{t('btns.edit')}</span>
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </FormWrapper>
    );
};

const BlobTable = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { userBlob, isLoading } = getUserBlob(activeAccount?.localAccountId);

    const services = get(userBlob, 'serviceOfUsers', []);

    return (
        <>
            {isLoading ? (
                <FlexCenter>
                    <Loading />
                </FlexCenter>
            ) : (
                <table className='table table-striped'>
                    <thead className='complementary-2-bg-b2 text-white'>
                        <tr>
                            <th scope='col'>{t('table.clmName')}</th>
                            <th scope='col'>{t('table.clmDescr')}</th>
                            <th scope='col'>{t('table.clmRef')}</th>
                            <th scope='col'>{t('table.clmRoles')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {services.map((s: IServiceOfUser) => (
                            <tr key={s.id}>
                                <td scope='col'>{s.name}</td>
                                <td scope='col'>{s.description ?? ''}</td>
                                <td scope='col'>{s.contactPerson ?? ''}</td>
                                <td scope='col'>{s.roles.length > 0 ? s.roles[0].name : ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default Home;
