import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-lora';
import 'typeface-roboto-mono';
import 'typeface-titillium-web';
import './index.scss';
import './localization/index'

import App from './App';
import { FontLoader } from 'design-react-kit';
import { MsalProvider } from '@azure/msal-react';
import { StrictMode } from 'react';
import { Toaster } from 'react-hot-toast';
import { _msal } from './msal/AdHelper';
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
    <StrictMode>
        <MsalProvider instance={_msal}>
            <App />
            <Toaster />
            <FontLoader />
        </MsalProvider>
    </StrictMode>,
);
