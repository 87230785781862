import { Card, PageDescription } from '../style';
import { useEffect, useState } from 'react';

import { IServiceOfUser } from '../model';
import MassiveRoleHandler from '../componets/MassiveRoleEditor/MassiveRoleHandler';
import Select from 'react-select';
import { get } from 'lodash';
import { getUserServices } from '../api/fetch';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 880px;
    gap: 1rem;
`;

const selectStyles = {
    control: (styles: any) => ({
        ...styles,
        width: '100%',
        marginBottom: '20px',
        borderColor: '#F90',
        colors: '#f90',
        borderRadius: '8px',
    }),
};

const selectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary: 'rgba(255, 153, 0, 0.6)',
        primary25: 'rgba(255, 153, 0, 0.6)',
        primary50: 'rgba(255, 153, 0, 0.6)',
        primary75: 'rgba(255, 153, 0, 0.6)',
    },
});

export const MassiveRoleEditor = () => {
    const [selectedService, setSelectedService] = useState<IServiceOfUser | null>(null);
    const { isError, isLoading, services } = getUserServices();
    const { t } = useTranslation();

    const handleSelectChange = (id: string) => {
        //Dalla lista dei servizi correnti seleziona quello con l'id corrispondente e lo setta come selected in modo da poterlo visualizzare nel form di modifica
        const selected = services.serviceForUserList.find((service: IServiceOfUser) => service.id === id);
        setSelectedService(selected);
    };

    const serviceList = get(services, 'serviceForUserList', []);

    //Crea una lista di opzioni per il select
    const options: { value: string; label: string }[] = serviceList.map((service: IServiceOfUser) => {
        return { value: service.id, label: service.name };
    });

    return (
        <>
            {isError && <Card>{t('errors.requestFailed')}</Card>}
            {isLoading && <Card>{t('generic.loading')}</Card>}

            {serviceList && (
                <Wrapper>
                    <PageDescription>{t('routes.editMassivRole')}</PageDescription>
                    <Select theme={selectTheme} styles={selectStyles} options={options} onChange={newValue => handleSelectChange(newValue!.value)} />
                    {selectedService && <MassiveRoleHandler selectStyles={selectStyles} selectTheme={selectTheme} service={selectedService} />}
                </Wrapper>
            )}
        </>
    );
};
