import styled from 'styled-components';

export const AccordionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 13rem);
`;

const AccordionItemWrapper = styled.div<{ $isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    border: 1px solid #f90;
    margin: 5px 0;
    border-radius: 4px;
    flex-grow: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
    transition: flex-grow 0.3s ease;
    overflow: hidden;
    color: white;
`;

const AccordionHeader = styled.div`
    background: #f90;
    padding: 10px;
    cursor: pointer;
`;

const AccordionContent = styled.div`
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
`;

const AccordionItem = ({ title, children, isOpen, onClick }: any) => {
    return (
        <AccordionItemWrapper $isOpen={isOpen}>
            <AccordionHeader onClick={onClick}>{title}</AccordionHeader>
            {isOpen && <AccordionContent>{children}</AccordionContent>}
        </AccordionItemWrapper>
    );
};

export default AccordionItem;
