import { memo, useId } from 'react';

import { Description } from '../../style';
import ErrorText from '../ErrorText/ErrorText';
import { ICustomSelectOption } from '../../model';
import { get } from 'lodash';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

interface ICustomSelect {
    name: string;
    parentOnChange?: (value: any) => void;
    options: ICustomSelectOption[];
    required?: boolean;
    label?: string;
    description?: string;
    wrapperClassName?: string;
    disabled?: boolean;
}

const StyledSelect = styled.select`
    height: auto;
    width: 100%;
`;
const StyledLabel = styled.label`
    font-weight: 600 !important;
    font-size: 0.875rem;
    color: #1a1a1a;
`;
const Option = styled.option`
    background-color: #f90;
    color: white;
    min-height: 400px;
`;
const CustomSelect = memo((props: ICustomSelect) => {
    const { setFieldValue, values, errors } = useFormikContext();
    const { t } = useTranslation();
    const { name, parentOnChange, options, label, required, description, wrapperClassName, disabled } = props;
    const dValue = get(values, `[${name}]`);
    const dValueObj = get(values, `[${name}].value`);
    const error = get(errors, `[${name}]`);

    const id = useId();
    const className = 'fw-normal';

    return (
        <div className={`select-wrapper ${wrapperClassName}`}>
            {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
            {description && <Description>{description}</Description>}
            <StyledSelect
                id={id}
                name={name}
                className={!!error ? 'is-invalid ' + className : className}
                required={required}
                defaultValue={!!dValueObj ? dValueObj : dValue !== undefined ? dValue : 'DEFAULT'}
                disabled={disabled}
                onChange={event => {
                    if (!!parentOnChange) parentOnChange(event.target.value);
                    setFieldValue(name, event.target.value);
                }}>
                <Option value='DEFAULT' disabled>
                    {t('generic.selectOption')}
                </Option>
                {options.map(data => (
                    <Option key={data.id} value={data.value}>
                        {data.label}
                    </Option>
                ))}
            </StyledSelect>
            {!!error && <ErrorText text={error} />}
        </div>
    );
});

export default CustomSelect;
