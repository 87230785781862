import Header from './Header';
import LeftDrawer from './LeftDrawer';
import { Outlet } from 'react-router-dom';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface ILayoutProps {
    children?: ReactNode;
}
const Page = styled.div`
    display: flex;
`;
const Container = styled.div`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 10.5rem);
`;
const Layout = (props: ILayoutProps) => (
    <>
        <Header />
        <Page>
            <LeftDrawer />
            <Container>{props.children ? props.children : <Outlet />}</Container>
        </Page>
    </>
);

export default Layout;
