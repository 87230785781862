import CustomSvg from '../Svg/CustomSvg';
import Dropdown from 'react-bootstrap/Dropdown';
import { get } from 'lodash';
import { route } from '../../route';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledDropdown = styled(Dropdown)`
    display: flex;
    padding: 0;
    min-width: 4rem;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    justify-content: space-between;
    background-color: #f90 !important;
    font-size: 1rem;
    padding: 0;
    font-weight: normal;
    align-items: center;
    gap: 0.5rem;
`;
const StyledDropdownItem = styled(Dropdown.Item)`
    text-decoration: none;
    color: #f90 !important;
`;

const UserBtn = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const name = get(instance.getActiveAccount(), 'idTokenClaims.family_name', 'username');
    const lastName = get(instance.getActiveAccount(), 'idTokenClaims.given_name', 'username');

    return (
        <div className='nav-item dropdown'>
            <StyledDropdown className='nav-link dropdown-toggle'>
                <StyledDropdownToggle variant='success' id='dropdown-basic' className='btn btn-primary btn-icon btn-full'>
                    <span className='rounded-icon'>
                        <CustomSvg iconName='it-user' className='icon icon-primary' />
                    </span>
                    <span className='d-none d-lg-block'>
                        {name} {lastName}
                    </span>
                    <CustomSvg className='icon icon-white' iconName='it-expand' />
                </StyledDropdownToggle>
                <Dropdown.Menu>
                    <StyledDropdownItem
                        onClick={() => {
                            instance.logoutRedirect();
                        }}
                        className='text-primary'>
                        Logout
                    </StyledDropdownItem>
                </Dropdown.Menu>
            </StyledDropdown>
        </div>
    );
};

export default UserBtn;
