import { TOKEN, cryptData, decryptData } from '../utils';
import axios, { AxiosHeaderValue, AxiosHeaders, AxiosRequestConfig, AxiosResponseHeaders, Method } from 'axios';

import { _msal } from '../msal/AdHelper';
import { loginRequest } from '../msal/azureAd';

export interface IRequest<T = any> {
    url: string;
    baseURL: string;
    data?: T;
}

export interface IResponse<D = any> {
    data: D;
    status: number;
    statusText: string;
    headers: TResponseHeaders;
    config: IRequestConfig;
    request?: any;
}

export type TRequestHeaders = Record<string, AxiosHeaderValue | AxiosHeaders>;

interface IRequestConfig extends AxiosRequestConfig {}

type TResponseHeaders = AxiosResponseHeaders;

type TMethod = Method;

const httpClient = () => {
    const URL = process.env.REACT_APP_API_URL;

    const _instance = axios.create({
        timeout: parseInt(process.env.TIMEOUT_HTTPCLIENT as string),
    });

    _instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(TOKEN);

    _instance.interceptors.response.use(
        res => res,
        async error => {
            console.log(error);
            // Do something with response error
            const originalRequest = error.config;
            if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
                originalRequest._retry = true;
                let account = _msal.getActiveAccount();
                if (!!account) {
                    const access_token = await _msal.acquireTokenSilent({ account, ...loginRequest });
                    localStorage.setItem(TOKEN, access_token.accessToken);
                    _instance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token.accessToken;
                } else {
                    _msal.loginRedirect();
                }

                return _instance(originalRequest);
            }

            return Promise.reject(error);
        },
    );

    const fetcher = (url: string) => _instance.get(URL + url, { headers: { Authorization: `Bearer ${localStorage.getItem(TOKEN)}` } }).then(res => decryptData(res.data));

    const postRequest = (url: string, { arg }: { arg: any }) =>
        _instance.post(URL + url, cryptData(arg), { headers: { Authorization: `Bearer ${localStorage.getItem(TOKEN)}`, 'Content-Type': 'application/json' } }).then(res => decryptData(res.data));

    const putRequest = (url: string, { arg }: { arg: any }) =>
        _instance.put(URL + url, cryptData(arg), { headers: { Authorization: `Bearer ${localStorage.getItem(TOKEN)}`, 'Content-Type': 'application/json' } }).then(res => decryptData(res.data));
    return {
        fetcher,
        postRequest,
        putRequest,
    };
};

export default httpClient();
