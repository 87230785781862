import { ButtonLink, Drawer, DrawerBtnLink, ExternalLink, Line, LinksContainer, StyledAccordion, StyledAccordionHeader } from '../style/layout/drawer';
import { useContext, useState } from 'react';

import { AccessLevel } from '../model/enum';
import Accordion from 'react-bootstrap/Accordion';
import { LoggedUserContext } from '../componets/UserContext/userContext';
import { get } from 'lodash';
import { route } from '../route';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ILeftDrawerProps {
    forMobile?: boolean;
    setExpanded?: any;
}

const LeftDrawer = (props: ILeftDrawerProps) => {
    const { forMobile, setExpanded } = props;
    const navigate = useNavigate();
    const [active, setActive] = useState<string>('');
    const { t, i18n } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);
    const aL = get(loggedUser, 'accessLevel', 0);
    const DRAWER_LINKS_NONE: { mainName: string; path?: string; children: { name: string; path: string; key: string; isExternal?: boolean }[] }[] = [
        {
            mainName: t('routes.home'),
            children: [],
            path: route.home,
        },
    ];
    const DRAWER_LINKS_WRITER: { mainName: string; path?: string; children: { name: string; path: string; key: string; isExternal?: boolean }[] }[] = [
        {
            mainName: t('routes.home'),
            children: [],
            path: route.home,
        },
        {
            mainName: t('routes.users'),
            children: [],
            path: route.users,
        },
        {
            mainName: t('routes.addUser'),
            children: [],
            path: route.addUsers,
        },
    ];
    const DRAWER_LINKS_SERVICEM: { mainName: string; path?: string; children: { name: string; path: string; key: string; isExternal?: boolean }[] }[] = [
        {
            mainName: t('routes.home'),
            children: [],
            path: route.home,
        },
        {
            mainName: t('routes.services'),
            children: [],
            path: route.services,
        },
        {
            mainName: t('routes.users'),
            children: [],
            path: route.users,
        },
        {
            mainName: t('routes.addUser'),
            children: [],
            path: route.addUsers,
        },
        {
            mainName: t('routes.editMassivRole'),
            children: [],
            path: route.editRole,
        },
    ];
    const DRAWER_LINKS_ADMIN: { mainName: string; path?: string; children: { name: string; path: string; key: string; isExternal?: boolean }[] }[] = [
        {
            mainName: t('routes.home'),
            children: [],
            path: route.home,
        },
        {
            mainName: t('routes.services'),
            children: [],
            path: route.services,
        },
        {
            mainName: t('routes.users'),
            children: [],
            path: route.users,
        },
        {
            mainName: t('routes.addUser'),
            children: [],
            path: route.addUsers,
        },
        {
            mainName: t('routes.editMassivRole'),
            children: [],
            path: route.editRole,
        },
        {
            mainName: t('routes.monitoring'),
            children: [],
            path: route.monitoring,
        },
    ];
    const links = {
        [AccessLevel.None]: DRAWER_LINKS_NONE,
        [AccessLevel.Writer]: DRAWER_LINKS_WRITER,
        [AccessLevel.ServiceManager]: DRAWER_LINKS_SERVICEM,
        [AccessLevel.Admin]: DRAWER_LINKS_ADMIN,
    };

    return (
        <DrawerForDesktop forMobile={!!forMobile}>
            {links[aL as AccessLevel].map((data, index) => (
                <Accordion.Item eventKey={`${index}`} key={data.mainName + index}>
                    {!!data.children && data.children.length > 0 ? (
                        <>
                            <StyledAccordionHeader>{data.mainName}</StyledAccordionHeader>
                            <Accordion.Body>
                                {data.children.map(c => (
                                    <LinksContainer $forMobile={!!forMobile} key={c.key}>
                                        {get(c, 'isExternal', false) ? (
                                            <ExternalLink to={c.path} target='_blank'>
                                                {c.name}
                                            </ExternalLink>
                                        ) : (
                                            <>
                                                <Line $active={active === c.key} />
                                                <DrawerBtnLink
                                                    $forMobile={!!forMobile}
                                                    $active={active === c.key}
                                                    onClick={() => {
                                                        setActive(c.key);
                                                        navigate(c.path);
                                                        if (!!setExpanded) setExpanded(false);
                                                    }}>
                                                    {c.name}
                                                </DrawerBtnLink>
                                            </>
                                        )}
                                    </LinksContainer>
                                ))}
                            </Accordion.Body>
                        </>
                    ) : (
                        <ButtonLink
                            $forMobile={!!forMobile}
                            $active={active === data.path}
                            onClick={() => {
                                if (data.path) {
                                    setActive(data.path);
                                    navigate(data.path);
                                    if (!!setExpanded) setExpanded(false);
                                }
                            }}>
                            {data.mainName}
                        </ButtonLink>
                    )}
                </Accordion.Item>
            ))}
        </DrawerForDesktop>
    );
};

const DrawerForDesktop = ({ children, forMobile }: { children: any; forMobile: boolean }) => (
    <>
        {forMobile ? (
            <Accordion>{children}</Accordion>
        ) : (
            <Drawer>
                <div className='link-list-wrapper'>
                    <ul className='link-list d-flex flex-column'>
                        <StyledAccordion defaultActiveKey='0' flush>
                            {children}
                        </StyledAccordion>
                    </ul>
                </div>
            </Drawer>
        )}
    </>
);

export default LeftDrawer;
