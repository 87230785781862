import { Link, useRouteError } from 'react-router-dom';

import CustomSvg from '../componets/Svg/CustomSvg';
import { ToastWrapper } from '../style';
import { route } from '.';
import { useTranslation } from 'react-i18next';

const ErrorBoundary = () => {
    let error = useRouteError();
    const { t } = useTranslation();
    console.log(error);
    return (
        <div>
            <ToastWrapper>
                <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
            </ToastWrapper>
            <ToastWrapper>
                <blockquote className='blockquote'>
                    <p className='mb-0'> {t('generic.errorRoute', { msg: `${error}` })}</p>
                    <Link to={route.home}>{t('generic.backToHome')}</Link>
                </blockquote>
            </ToastWrapper>
        </div>
    );
};

export default ErrorBoundary;
