import { Btn } from '../componets/Buttons/Buttons';
import CustomSvg from '../componets/Svg/CustomSvg';
import { FlexCenter } from '../style';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

interface ILandingProps {}

const LandingContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #f90;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 200px;
    width: 200px;
    border-radius: 4px;
    align-items: center;
    gap: 16px;
    opacity: 1;
`;
const ImgContainer = styled.div`
    top: 80px;
    left: 50%;
`;
const Landing = (props: ILandingProps) => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect({ scopes: [process.env.REACT_APP_SCOPE ?? ''], extraQueryParameters: { spidl: '2', authLogin: 'spidpf,cie,cns,aad' } });
    }, []);
    return (
        <LandingContainer>
            <div className='col-8 col-lg-4'>
                <div className='card-wrapper card-space'>
                    <div className='card card-teaser rounded shadow'>
                        <div className='card-body'>
                            <FlexCenter className='mt-4'>
                                <h3 className='card-title h5'>
                                    <CustomSvg className='icon' iconName='it-files' />
                                    {t('generic.title')}
                                </h3>
                            </FlexCenter>

                            <FlexCenter className='mt-4'>
                                <Btn
                                    text='Login'
                                    onClick={() =>
                                        instance.loginRedirect({ scopes: [process.env.REACT_APP_SCOPE ?? ''], extraQueryParameters: { spidl: '2', authLogin: 'spidpf,cie,cns,aad' } })
                                    }></Btn>
                            </FlexCenter>
                        </div>
                    </div>
                </div>
            </div>
        </LandingContainer>
    );
};

export default Landing;
