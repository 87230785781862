import { AlertError, AlertSuccess } from '../componets/Alert/Alert';
import { Card, InfoBox, PageDescription } from '../style';
import ReactSelect, { SingleValue } from 'react-select';
import { cryptData, isServiceManager } from '../utils';
import { useContext, useState } from 'react';

import { AccessLevel } from '../model/enum';
import { Formik } from 'formik';
import { IServiceOfUser } from '../model';
import Loading from '../componets/Loading/Loading';
import { LoggedUserContext } from '../componets/UserContext/userContext';
import RoleHandler from '../componets/ServiceManagement/RoleHandler';
import { get } from 'lodash';
import { getUserServices } from '../api/fetch';
import httpClient from '../api/httpClient';
import { newServiceDefaults } from '../utils/constants';
import styled from 'styled-components';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`;

const Button = styled.button`
    padding: 0.8rem 1rem;
    border-radius: 8px;
    background-color: #f90;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    color: white;
    border: none;
`;

const TwoColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    max-width: 1200px;
    gap: 1rem;
    @media (min-width: 1200px) {
        flex-direction: row;
    }
`;

const ServiceManagement = () => {
    const { t } = useTranslation();
    const [newService, setNewService] = useState(false);
    const [selectedService, setSelectedService] = useState<IServiceOfUser | null>(null);
    const { trigger } = useSWRMutation('UpdateService', httpClient.postRequest);
    const { loggedUser } = useContext(LoggedUserContext);

    const isSm = isServiceManager(loggedUser?.accessLevel as AccessLevel);

    const { isError, isLoading, services, mutate, isValidating } = getUserServices(isSm ? loggedUser?.userObjectId : undefined);

    const handleSelectChange = (id: string) => {
        //Dalla lista dei servizi correnti seleziona quello con l'id corrispondente e lo setta come selected in modo da poterlo visualizzare nel form di modifica
        const selected = services.serviceForUserList.find((service: IServiceOfUser) => service.id === id);
        setSelectedService(selected);
    };

    const handleDeleteService = async (id: string) => {
        try {
            const response = await httpClient.fetcher(`DeleteService?${cryptData(`serviceId=${id}`, true)}`);

            if (response) {
                AlertSuccess(t('generic.success'));
                setSelectedService(null);
                mutate();
            }
        } catch (error) {
            AlertError(t('generic.error'));
        }
    };

    const serviceList = get(services, 'serviceForUserList', []);

    return (
        <>
            {isLoading && isValidating && <Card>{t('generic.loading')}</Card>}
            {isError && <Card>{t('errors.requestFailed')}</Card>}

            {!isLoading && !isError && !!serviceList && (
                <TwoColumnWrapper>
                    <Card>
                        <PageDescription>{t('routes.services')}</PageDescription>
                        {!newService && (
                            <TwoColumnWrapper>
                                <TwoColumnWrapper className='select-wrapper' style={{ paddingTop: '1rem' }}>
                                    <ReactSelect
                                        placeholder={t('generic.selectOption')}
                                        onChange={(newValue: SingleValue<{ value: string; label: string; id: string }>) => {
                                            handleSelectChange(!!newValue ? newValue?.id : '');
                                        }}
                                        options={serviceList.map((s: IServiceOfUser) => ({ value: s.id, label: s.name, id: s.id }))}
                                    />
                                </TwoColumnWrapper>

                                <Button
                                    style={{ maxWidth: '300px' }}
                                    type='button'
                                    id='button-3'
                                    onClick={() => {
                                        setNewService(prev => !prev);
                                        setSelectedService(null);
                                    }}>
                                    {t('generic.createService')}
                                </Button>
                            </TwoColumnWrapper>
                        )}

                        {/*SELECTED SERVICE FORM */}
                        {selectedService && (
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                enableReinitialize={true}
                                initialValues={selectedService}
                                onSubmit={async (values, { resetForm }) => {
                                    try {
                                        const response = await trigger({
                                            ...selectedService,
                                            name: values.name,
                                            description: values.description,
                                            contactPerson: values.contactPerson,
                                        });

                                        if (response) {
                                            AlertSuccess(t('generic.success'));
                                            setSelectedService(null);
                                            resetForm();
                                            mutate();
                                        }
                                    } catch (error) {
                                        AlertError(t('generic.error'));
                                    }
                                }}>
                                {({ values, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <input type='text' className='form-control' value={values.id} name='id' placeholder='Id' disabled onChange={handleChange} />
                                        <input type='text' className='form-control' value={values.name} name='name' placeholder='Nome' onChange={handleChange} />
                                        <input type='text' className='form-control' value={values.description} name='description' placeholder='Descrizione' onChange={handleChange} />
                                        <input type='text' className='form-control' value={values.contactPerson} name='reference' placeholder='Referenze' onChange={handleChange} />
                                        <ButtonWrapper>
                                            <Button type='submit' id='button-3'>
                                                {t('btns.edit')}
                                            </Button>
                                            <Button type='button' id='button-3' onClick={() => setSelectedService(null)}>
                                                {t('btns.cancel')}
                                            </Button>
                                            <Button type='button' id='button-3' onClick={() => handleDeleteService(values.id)}>
                                                {t('btns.delete')}
                                            </Button>
                                        </ButtonWrapper>
                                    </form>
                                )}
                            </Formik>
                        )}

                        {/*NEW SERVICE FORM */}
                        {newService && (
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                initialValues={newServiceDefaults}
                                onSubmit={async (values, { resetForm }) => {
                                    try {
                                        const response = await trigger({
                                            id: newServiceDefaults.id,
                                            name: values.name,
                                            description: values.description,
                                            grantorId: loggedUser?.userObjectId ?? '',
                                            contactPerson: values.reference,
                                            contactPersonId: '00000000-0000-0000-0000-000000000000',
                                            roles: [],
                                            isVisible: true,
                                            startDate: null,
                                            endDate: null,
                                        });

                                        if (response) {
                                            AlertSuccess(t('generic.success'));
                                            resetForm();
                                            mutate();
                                            setNewService(false);
                                        }
                                    } catch (error) {
                                        AlertError(t('generic.error'));
                                    }
                                }}>
                                {({ values, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <input type='text' className='form-control' value={values.id} name='id' placeholder='id' disabled onChange={handleChange} />
                                        <input type='text' className='form-control' name='name' placeholder='Nome' onChange={handleChange} />
                                        <input type='text' className='form-control' name='description' placeholder='Descrizione' onChange={handleChange} />
                                        <input type='text' className='form-control' name='reference' placeholder='Referenze' onChange={handleChange} />
                                        <ButtonWrapper>
                                            <Button type='submit' id='button-3'>
                                                {t('btns.create')}
                                            </Button>
                                            <Button type='button' id='button-3' onClick={() => setNewService(prev => !prev)}>
                                                {t('btns.cancel')}
                                            </Button>
                                        </ButtonWrapper>
                                    </form>
                                )}
                            </Formik>
                        )}
                        {!newService && !selectedService && <InfoBox>{t('generic.inputServices')}</InfoBox>}
                    </Card>

                    <Card>
                        <PageDescription>{t('services.man')}</PageDescription>
                        {selectedService ? <RoleHandler selectedService={selectedService} setSelectedService={setSelectedService} /> : <InfoBox>{t('services.unselectedServ')}</InfoBox>}
                    </Card>
                </TwoColumnWrapper>
            )}
        </>
    );
};

export default ServiceManagement;
