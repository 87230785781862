import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const getFieldErrorNames = (formikErrors: any) => {
    const transformObjectToDotNotation = (obj: any, prefix = '', result: string[] = []) => {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });
        return result;
    };
    return transformObjectToDotNotation(formikErrors);
};
interface IScrollField {
    forceScroll?: number;
}
const ScrollToFieldError = ({ forceScroll }: IScrollField) => {
    const { submitCount, isValid, errors } = useFormikContext();
    useEffect(() => {
        if (isValid) return;
        const fieldErrorNames = getFieldErrorNames(errors);
        if (fieldErrorNames.length <= 0) return;
        const elements = document.getElementsByClassName(`formik-error`);
        if (elements.length < 1) return;
        // Scroll to first known error into view
        elements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [submitCount, isValid, forceScroll]);

    return null;
};
export default ScrollToFieldError;
