import { cryptData } from '../utils';
import httpClient from './httpClient';
import useSWRImmutable from 'swr/immutable';

//Role manager
export function getUserBlob(userId?: string) {
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(!!userId ? `getUserBlob?${cryptData(`userId=${userId}`, true)}` : null, httpClient.fetcher, {
        refreshInterval: 240000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        userBlob: data,
        isLoading,
        isError: error,
        mutate,
        isValidating,
    };
}

export function getUserServices(userId?: string, isWriter?: boolean) {
    const query = isWriter ? null : `GetAllServices${!!userId ? `?${cryptData(`userId=${userId}`, true)}` : ''}`;
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(query, httpClient.fetcher, {
        refreshInterval: 2400000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        services: data || [],
        isLoading,
        isError: error,
        mutate,
        isValidating,
    };
}

export function getUserRoles() {
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable('GetAllServices', httpClient.fetcher, {
        refreshInterval: 2400000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        services: data,
        isLoading,
        isError: error,
        mutate,
        isValidating,
    };
}

export interface IMonitoringData {
    usersCountByAccessLevel: UsersCountByAccessLevel[];
    servicesIdsAndNames: ServicesIdsAndName[];
}

export interface UsersCountByAccessLevel {
    key: number;
    value: number;
}

export interface ServicesIdsAndName {
    key: string;
    value: string;
}
export function getMonitoringData() {
    const { data, error, isLoading, isValidating } = useSWRImmutable('GetUsersData', httpClient.fetcher, {
        refreshInterval: 2400000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        data: data as IMonitoringData,
        isLoading,
        isError: error,
        isValidating,
    };
}

export interface IGetServiceData {
    roleIdentifiers: IRoleIdentifier[];
    userRolesCountByPowerLevel: UserRolesCountByPowerLevel;
    userServicesAssignedCount: number;
}

export interface IRoleIdentifier {
    id: string;
    name: string;
}

export interface UserRolesCountByPowerLevel {
    [key: string]: UsersCountByAccessLevel[];
}

export function getServiceData(serviceId?: string) {
    const { data, error, isLoading, isValidating } = useSWRImmutable(serviceId ? `GetServiceData?${cryptData(`serviceId=${serviceId}`, true)}` : null, httpClient.fetcher, {
        refreshInterval: 2400000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        data: data as IGetServiceData,
        isLoading,
        isError: error,
        isValidating,
    };
}
