import { ChangeEventHandler, useId } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

interface ICustomCheckboxProps {
    label?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    value?: boolean;
}

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: transparent;
    align-items: center;
    .input-group-text {
        background-color: transparent;
    }
`;

const CustomCheckbox = (props: ICustomCheckboxProps) => {
    const { label, defaultChecked, disabled, onChange, value } = props;
    const id = useId();
    return (
        <CheckboxContainer>
            <InputGroup.Checkbox aria-label='Checkbox' id={id} checked={value} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange} />
            {label && <label htmlFor={id}>{label}</label>}
        </CheckboxContainer>
    );
};

export default CustomCheckbox;
