import { ELanguages, changeLanguage, languages } from '../../localization';

import CustomSvg from '../Svg/CustomSvg';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledDropdown = styled(Dropdown)`
    display: flex;
    padding: 0;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    justify-content: space-between;
    background-color: #f90 !important;
    font-size: 1rem;
    padding: 0.5rem;
    font-weight: normal;
    align-items: center;
    gap: 0.5rem;
`;
const StyledDropdownItem = styled(Dropdown.Item)`
    text-decoration: none;
    color: #f90 !important;
`;

const LanguageBtn = () => {
    const { i18n } = useTranslation();

    const isIt = i18n.language === ELanguages.it;
    const isDe = i18n.language === ELanguages.de;
    const isEn = i18n.language === ELanguages.en;

    return (
        <div className='nav-item dropdown'>
            <StyledDropdown className='p-2 nav-link dropdown-toggle'>
                <StyledDropdownToggle variant='success' id='dropdown-basic'>
                    {isIt ? 'IT' : isDe ? 'DE' : 'EN'}
                    <CustomSvg className='icon icon-white' iconName='it-expand' />
                </StyledDropdownToggle>
                <Dropdown.Menu>
                    {isIt && (
                        <>
                            <StyledDropdownItem onClick={() => changeLanguage(ELanguages.de)}>{languages[ELanguages.de]}</StyledDropdownItem>
                            <StyledDropdownItem onClick={() => changeLanguage(ELanguages.en)}>{languages[ELanguages.en]}</StyledDropdownItem>
                        </>
                    )}
                    {isDe && (
                        <>
                            <StyledDropdownItem onClick={() => changeLanguage(ELanguages.it)}>{languages[ELanguages.it]}</StyledDropdownItem>
                            <StyledDropdownItem onClick={() => changeLanguage(ELanguages.en)}>{languages[ELanguages.en]}</StyledDropdownItem>
                        </>
                    )}
                    {isEn && (
                        <>
                            <StyledDropdownItem onClick={() => changeLanguage(ELanguages.it)}>{languages[ELanguages.it]}</StyledDropdownItem>
                            <StyledDropdownItem onClick={() => changeLanguage(ELanguages.de)}>{languages[ELanguages.de]}</StyledDropdownItem>
                        </>
                    )}
                </Dropdown.Menu>
            </StyledDropdown>
        </div>
    );
};

export default LanguageBtn;
